/** @format */

import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Text,
  TextInput,
  useMantineTheme,
  Anchor,
} from "@mantine/core";
import { useState } from "react";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "./validation";
import { postForgotPasswordReq } from "./service/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialForgotPasswordValue = {
  email: "",
};

export default function ForgotPassword() {
  const theme = useMantineTheme();
  const [error, setError] = useState("");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialForgotPasswordValue,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      postForgotPasswordReq(values?.email)
        .then((res) => {
          toast.success("Email sent!");
          setError("");
          resetForm();
        })
        .catch((err) => {
          setError(err);
          resetForm();
        });
    },
  });

  return (
    <Box>
      <Center style={{ width: "100%", height: "100vh" }}>
        <Container p={10} style={{ width: "100%", maxWidth: "400px" }}>
          <Card
            radius={10}
            shadow={"lg"}
            style={{
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            p={30}
          >
            <Box my={10}>
              <Center>
                <Text color={theme.colors.blue[4]} weight={700}>
                  Forgot Password?
                </Text>
              </Center>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextInput
                my={7}
                mx={5}
                name="email"
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Enter your Email
                  </p>
                }
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email ? errors.email : null}
              />

              {error && (
                <Center>
                  <Text color="red">{error}</Text>
                </Center>
              )}

              <Center mt={10} mx={5} mb={20}>
                <Button
                  fullWidth
                  type="submit"
                  variant="filled"
                  style={{ fontSize: "18px" }}
                >
                  Send Email
                </Button>
              </Center>
            </form>

            <Center mt={20}>
              <Text variant="link" color="blue">
                <Anchor href="/login">Back to Login</Anchor>
              </Text>
            </Center>
          </Card>
        </Container>
      </Center>
    </Box>
  );
}

