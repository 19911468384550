/** @format */

import {
  Card,
  Box,
  Table,
  Center,
  Loader,
  Button,
  Grid,
  Text,
  useMantineTheme,
  TextInput,
  Input,
  Flex,
  Textarea,
  Pagination,
  Image,
  Modal,
} from "@mantine/core";
import { Add, Delete, MovieCreation, RemoveRedEye } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";

import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useRef, useState } from "react";
import CustomModal from "../../component/customModal";
import { Dropzone } from "@mantine/dropzone";
import { Formik } from "formik";
import { projectValidationSchema } from "../../constant/validation";
import { useDebounce } from "../../hooks";
import { notifications } from "@mantine/notifications";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createProjectReq,
  deleteProjectReq,
  getListReq,
  searchFromList,
} from "../dashboard/service/service";
import file from "../../assets/images/file.png";
let interval;
const Project = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [isSubmitted, setSubmitted] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [delConfirmationOpened, setDelConfirmationOpened] = useState(false);
  const [delId, setDelId] = useState("");
  const itemsPerPage = 10;
  const currentPageRef = useRef<number>();
  const option = [
    { label: "Select Type", value: "", disable: true },
    { label: "YouTube", value: "youtube", disable: false },
    { label: "Media File", value: "upload", disable: false },
  ];
  const [initialValues, setInitialValues] = useState({
    projectName: "",
    type: "",
    projectSrc: "",
    description: "",
  });

  const theme = useMantineTheme();
  useEffect(() => {
    getListReq(itemsPerPage, currentPage)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        setLoading(false);
        setProjectList(res);
      })
      .catch((err: any) => {
        if (err?.response.status == "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          console.log(err);

          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });

          setProjectList([]);
        }
      });
  }, [currentPage]);
  const openDelModal = (id: any) => {
    setDelConfirmationOpened(true);
    setDelId(id);
  };
  const handleDelete = async () => {
    try {
      const res = await deleteProjectReq(delId);
      setProjectList(res);
      setDelConfirmationOpened(false);
      toast.success("Project deleted successfully");
    } catch (error) {
      console.log("An error occurred while deleting project", error);
      toast.error("An error occurred while deleting project");
    }
  };

  const handleGeneratePdf = async (id) => {
    try {
      navigate(`/report/${id}`);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const rows = projectList.data
    ?.map((element: any, index: number) => (
      <tr key={index}>
        <td width={250} align="center">
          <Text>{element.projectName}</Text>
        </td>

        <td width={500}>
          <Text
            my={"xs"}
            size={"sm"}
            color={element.status === "success" ? "green" : "red"}
          >
            {element.projectDescription}
          </Text>
        </td>
        <td align="center">
          <Text>{element.type === "upload" ? "Upload" : "Youtube"}</Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === "Completed"
                  ? "green"
                  : element.status === "Failed"
                  ? "red"
                  : "rgb(0, 30, 107)"
              }`,
            }}
          >
            {element.status}
            {/* {element.status === 'Completed' && !element?.isSaveEmbedding
              ? 'Processing'
              : element.status === 'Completed' && !element?.isSaveEmbedding} */}
          </Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === "Completed" && element?.isSaveEmbeddings
                  ? "green"
                  : element.status === "Failed" && !element?.isSaveEmbeddings
                  ? "red"
                  : "rgb(0, 30, 107)"
              }`,
            }}
          >
            {(element.status === "Completed" ||
              element.status === "Initialising") &&
            !element?.isSaveEmbeddings
              ? "Processing"
              : element.status === "Failed" && !element?.isSaveEmbeddings
              ? "Failed"
              : element.status === "Completed" && element?.isSaveEmbeddings
              ? "Available"
              : "Initialising"}
          </Text>
        </td>
        <td align="center">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 8,
            }}
          >
            {" "}
            <Button
              title="Redirect to Analysis tab"
              variant="filled"
              color="light"
              size="xs"
              disabled={element.status !== "Completed" ? true : false}
              onClick={() => {
                if (element.status === "Completed") {
                  clearInterval(interval);
                  navigate(`/instavidiqDetail?id=${element._id}`);
                }
              }}
            >
              <Text>View</Text>
            </Button>
            <Button
              title="Generate PDF Report"
              variant="filled"
              color="cyan.6"
              size="xs"
              p={8}
              disabled={element.status !== "Completed" ? true : false}
              onClick={() => {
                if (element.status === "Completed") {
                  handleGeneratePdf(element._id);
                }
              }}
            >
              View Report
            </Button>
            <Button
              title="Remove from InstaVidIQ"
              variant="filled"
              color="red"
              p={5}
              size="xs"
              onClick={() => {
                openDelModal(element._id);
              }}
            >
              <Text> Delete</Text>
            </Button>
          </Box>
        </td>
      </tr>
    ))
    .reverse();

  const handleSubmit = async (value) => {
    setSubmitted(false);
    const formValue = {
      projectDescription: value.description,
      projectName: value.projectName,
      projectLabels: "",
      type: value?.type ? value?.type : "upload",
      link: value.type === "youtube" ? value.projectSrc : "",
      video_file: value.projectSrc,
    };

    const data = new FormData();

    Object.entries(formValue).map(([keyName, value]: any) => {
      data.append(keyName, value);
    });
    createProjectReq(data)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        getListReq(itemsPerPage, currentPage)
          .then((res: any) => {
            setProjectList(res);
            setOpened(false);
            setSubmitted(true);
            setLoading(false);
          })
          .catch((err) => {
            if (err?.response.status == "401") {
              localStorage.removeItem("access_token");
              navigate("/login");
            } else {
              notifications.show({
                message: err?.message,
                color: "red",
                autoClose: 2000,
                icon: <ErrorIcon />,
              });
              setProjectList([]);
            }
          });
      })
      .catch((err) => {
        console.log("ERROR: ", err?.message);
      });
  };
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const handleRefresh = () => {
    getListReq(itemsPerPage, currentPageRef.current)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        setLoading(false);
        setProjectList(res);
      })
      .catch((err) => {
        if (err?.response.status == "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setProjectList([...projectList]);
        }
      });
  };

  const handleModel = () => {
    setOpened(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchProjects = async (filterText: SetStateAction<string>) => {
    try {
      const res = await searchFromList(filterText, itemsPerPage, currentPage);
      setProjectList(res);
    } catch (error) {
      window.alert(error);
    }

    setSearch(filterText);
  };
  const debouncedFetchProjects = useDebounce(fetchProjects, 500);
  useEffect(() => {
    if (search === "") {
      interval = setInterval(() => handleRefresh(), 6000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [search]);

  return (
    <>
      <Modal
        opened={delConfirmationOpened}
        onClose={() => setDelConfirmationOpened(false)}
        title="Are you sure you want to delete this project?"
      >
        <Button
          onClick={handleDelete}
          variant="outline"
          color="red"
          style={{ marginRight: "20px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setDelConfirmationOpened(false)}
          variant="outline"
          color="gray"
        >
          No
        </Button>
      </Modal>
      <CustomModal
        isOpen={opened}
        onClose={() => setOpened(false)}
        title={"New"}
        size="30rem"
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={projectValidationSchema}
        >
          {({
            values,
            touched,
            handleBlur,
            handleChange,
            errors,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <fieldset style={{ border: "none" }}>
                  <Input.Wrapper
                    label="Name"
                    m={10}
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <TextInput
                      type="text"
                      name="projectName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.projectName && errors.projectName
                          ? `${errors.projectName}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    label="Description"
                    m={10}
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <Textarea
                      placeholder="Add Description..."
                      name="description"
                      minRows={2}
                      maxRows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.description && errors.description
                          ? `${errors.description}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    m={10}
                    label="Type"
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <Input
                      component="select"
                      placeholder="Select type"
                      name="type"
                      value={values.type}
                      error={
                        touched.type && errors.type ? `${errors.type}` : null
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("projectSrc", "");
                      }}
                      onBlur={handleBlur}
                      // rightSection={<KeyboardArrowDownOutlined />}
                    >
                      {option.map((val, ind) => {
                        return (
                          <option
                            disabled={val.disable}
                            style={{ padding: "25px", height: "50px" }}
                            key={ind}
                            value={val.value}
                          >
                            {val.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Input.Wrapper>
                  {values.type === "upload" && (
                    <Input.Wrapper
                      m={10}
                      mt={20}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <Dropzone
                        accept={[
                          "video/x-msvideo",
                          "video/mp4",
                          "video/mpeg",
                          "video/ogg",
                          "video/mp2t",
                          "video/webm",
                          "video/3gpp",
                          "video/3gpp2",
                        ]}
                        onDrop={(e) => {
                          setFieldValue("projectSrc", e[0]);
                        }}
                        onBlur={handleBlur}
                        name="projectSrc"
                      >
                        {values.projectSrc === "" && (
                          <Text align="center">Drop Video here</Text>
                        )}
                        {values.projectSrc !== "" && (
                          <Flex>
                            <div>
                              <MovieCreation />
                            </div>
                            <Text align="center">{values.projectSrc.name}</Text>
                          </Flex>
                        )}
                      </Dropzone>
                    </Input.Wrapper>
                  )}
                  {values.type === "youtube" && (
                    <Input.Wrapper
                      m={10}
                      label="YouTube Link"
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <TextInput
                        value={values.projectSrc}
                        name="projectSrc"
                        placeholder="paste Your YouTube link here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.projectSrc && errors.projectSrc
                            ? `${errors.projectSrc}`
                            : null
                        }
                        // rightSection={<LinkRounded />}
                      />
                    </Input.Wrapper>
                  )}
                  <Input.Wrapper m={10} mt={30}>
                    <Button
                      type="submit"
                      variant="filled"
                      leftIcon={
                        !isSubmitted ? (
                          <Loader size={20} color="#ffffff" />
                        ) : null
                      }
                    >
                      {values.type === "upload" && !isSubmitted
                        ? "Uploading..."
                        : "Create  "}
                    </Button>
                  </Input.Wrapper>
                </fieldset>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Grid my={"2px"} gutter={"xs"}>
          <Grid.Col sm={6} md={4} lg={4}>
            <Text style={{ fontSize: "25px", fontWeight: 700 }}>
              InstaVidIQ
            </Text>
          </Grid.Col>

          <Grid.Col sm={6} md={4} lg={8}>
            <Flex justify={"right"} align={"center"} gap={20}>
              <TextInput
                autoFocus
                placeholder="Search"
                onChange={(e) => debouncedFetchProjects(e.target.value)}
              />

              {/* <ActionIcon variant="gradient" mx={10} onClick={handleRefresh}>
                <Refresh />
              </ActionIcon> */}

              <Button
                variant="gradient"
                style={{ float: "right" }}
                leftIcon={<Add />}
                onClick={handleModel}
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        <Box style={{ marginTop: "25px" }}>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Name</th>
                <th>Description</th>
                <th style={{ textAlign: "center" }}>Type</th>
                <th style={{ textAlign: "center" }}>Status</th>{" "}
                <th style={{ textAlign: "center" }}>Chat</th>{" "}
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                      <Loader variant="bars" />
                    </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{rows}</tbody>
            )}
          </Table>
          <br />
          <br />
          <Pagination
            value={currentPage}
            onChange={handlePageChange}
            total={projectList.totalPages}
            style={{ position: "absolute", right: 30, bottom: 25 }}
          />
        </Box>
      </Card>
    </>
  );
};

export default Project;
