import {
  Card,
  Box,
  Table,
  Center,
  Loader,
  Button,
  Grid,
  Text,
  Pagination,
  Image,
  useMantineTheme,
  Group,
} from "@mantine/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Delete, FileCopy, RemoveRedEye } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { notifications } from "@mantine/notifications";

import { getFeedbackResponsesListReq } from "../dashboard/service/service";
import { getFeedbackQuestionDetail } from "../feedback/service/service";

import "react-toastify/dist/ReactToastify.css";

const Responses = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [isLoading, setLoading] = useState(true);
  const [feedbackResponsesList, setFeedbackResponseskList] = useState<any>([]);
  const [feedbackDetails, setFeedbackDetails] = useState<any>([]);
  const [update, setUpdate] = useState(false);
  let interval;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;
  const { id } = useParams();
  const currentPageRef = useRef<number>();

  useEffect(() => {
    getFeedbackResponsesListReq(id, itemsPerPage, currentPage)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        if (res.data.length <= 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        setLoading(false);
        setFeedbackResponseskList(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response.status === "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setFeedbackResponseskList([]);
        }
      });
  }, [currentPage, update]);
  useEffect(() => {
    getFeedbackQuestionDetail(id)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        setLoading(false);
        setFeedbackDetails(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response.status === "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setFeedbackDetails([]);
        }
      });
  }, [id]);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleGeneratePdf = async (id) => {
    try {
      navigate(`/surveyCine/response/report/${id}`);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);
  const handleRefresh = () => {
    getFeedbackResponsesListReq(id, itemsPerPage, currentPageRef.current)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        setLoading(false);
        setFeedbackResponseskList(res);
      })
      .catch((err) => {
        if (err?.response.status == "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setFeedbackResponseskList([...feedbackResponsesList]);
        }
      });
  };
  useEffect(() => {
    interval = setInterval(() => handleRefresh(), 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Card.Section inheritPadding py="xs">
          <Group spacing="sm">
            <Box
              style={{
                width: "100%",
                marginTop: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="white"
                style={{
                  fontSize: "20px",
                  padding: "0",
                }}
                leftIcon={<ArrowBackIosNewIcon />}
                onClick={() => window.history.back()}
              >
                Back
              </Button>
            </Box>
            <Box
              display="flex"
              style={{
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <Box style={{ flex: 1, minWidth: "200px" }}>
                <Text
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#333",
                  }}
                >
                  Question
                </Text>
                <Text style={{ fontSize: "16px", color: "#6c757d" }}>
                  {feedbackDetails?.question || "No Question available"}
                </Text>
              </Box>
            </Box>
          </Group>
        </Card.Section>

        <Box style={{ marginTop: "35px" }}>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Chat</th>
                <th
                  style={{
                    textAlign: "center",
                    width: "25%",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={12}>
                    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                      <Loader variant="bars" />
                    </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {feedbackResponsesList.data?.map((data, index) => (
                  <tr key={index}>
                    <td width={300} align="center">
                      <Text>{data.name}</Text>
                    </td>
                    <td align="center">
                      <Text
                        style={{
                          color: `${
                            data.status === "Completed"
                              ? "green"
                              : data.status === "Failed"
                              ? "red"
                              : "rgb(0, 30, 107)"
                          }`,
                        }}
                      >
                        {data.status}
                      </Text>
                    </td>
                    <td align="center">
                      <Text
                        style={{
                          color: `${
                            data.status === "Completed" &&
                            data?.isSaveEmbeddings
                              ? "green"
                              : data.status === "Failed" &&
                                !data?.isSaveEmbeddings
                              ? "red"
                              : "rgb(0, 30, 107)"
                          }`,
                        }}
                      >
                        {(data.status === "Completed" ||
                          data.status === "Initialising") &&
                        !data?.isSaveEmbeddings
                          ? "Processing"
                          : data.status === "Failed" && !data?.isSaveEmbeddings
                          ? "Failed"
                          : data.status === "Completed" &&
                            data?.isSaveEmbeddings
                          ? "Available"
                          : "Initialising"}
                      </Text>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        width: "100%",
                      }}
                    >
                      <Button
                        title="Redirect to Analysis tab"
                        variant="filled"
                        color="light"
                        disabled={data.status !== "Completed" ? true : false}
                        onClick={() => {
                          if (data.status === "Completed") {
                            clearInterval(interval as any);
                            navigate(
                              `/surveyCine/question/response?id=${data._id}`
                            );
                          }
                        }}
                      >
                        <Text>View</Text>
                      </Button>
                      <Button
                        title="Generate PDF Report"
                        variant="filled"
                        color="cyan.6"
                        disabled={data.status !== "Completed" ? true : false}
                        onClick={() => {
                          if (data.status === "Completed") {
                            handleGeneratePdf(data._id);
                          }
                        }}
                      >
                        <Text>View Report</Text>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          <br />
          <br />
          <Pagination
            value={currentPage}
            onChange={handlePageChange}
            total={feedbackResponsesList?.totalPages || 1}
            style={{ position: "absolute", right: 30, bottom: 25 }}
          />
        </Box>
      </Card>
    </>
  );
};

export default Responses;
