import React, { createContext, useContext, useState, ReactNode } from "react";
import Notification from "./notification";
// Define the shape of the notification context
interface NotificationContextType {
  addNotification: (
    type: "success" | "error" | "default",
    message: string,
    duration?: number,
    icon?: any
  ) => void;
}

// Create a context with the default value as undefined (initially)
const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

// Provider component to wrap the app with the notification context
interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);

  const addNotification = (
    type: "success" | "error" | "default",
    message: string,
    duration = 5000,
    icon = ""
  ) => {
    const id = Date.now();
    setNotifications(prevNotifications => [
      ...prevNotifications,
      { id, type, message, duration, icon },
    ]);
  };

  const removeNotification = (id: number) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notif => notif.id !== id)
    );
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="notification-container">
        {notifications.map(({ id, type, message, duration, icon }) => (
          <Notification
            key={id}
            id={id}
            type={type}
            message={message}
            duration={duration}
            Icon={icon}
            onClose={() => removeNotification(id)}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification system
export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
