/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-const */
import {
  Box,
  Card,
  Col,
  Container,
  Flex,
  Grid,
  Title,
  Text,
  Loader,
  Overlay,
  Image,
  ActionIcon,
  Dialog,
  Group,
  Textarea,
  Button,
  Skeleton,
  Tooltip,
} from "@mantine/core";
import "../dashboard/style.css";
import {
  Fragment,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Play from "../../assets/svg/play.svg";
import Pause from "../../assets/svg/pause.svg";
import Wave from "../../component/wave";
import CarouselFrame from "../../component/carouselFrame";
import ReplySharpIcon from "@mui/icons-material/ReplySharp";

import Graph from "../../component/graph/Graph";
import TextTyped from "../../component/TextTyped";
import PunctuationTyped from "../../component/PunctuationTyped";
import VisionAnalysis from "../../component/visionAnalysis";
import RoundChart from "../../component/roundChart";
import _ from "lodash";
import Sentiment from "../../component/sentiments";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { projectActions } from "./redux";
import {
  getProjectReq,
  getResponsesDeatilsReq,
  messageReq,
} from "./service/service";
import "./style.css";
import { MessageRounded } from "@mui/icons-material";
import { convertToMinutesAndSeconds } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import { transformDataToGraphFormat } from "../../component/graph/functions/functions";
import {
  findClosestTimestamp,
  findNearestTimestampsFromThumbnails,
  findEmotionIndex,
  convertToTimestampFormat,
  emotionBreakdownForGraph,
} from "./helpers/functions";
import { Carousel } from "@mantine/carousel";
interface Props {
  getProjectDetail: (id) => void;
}
function ProjectDetail(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { audioReq } = bindActionCreators(projectActions, dispatch);
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [isLoading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isAudioReady, setAudioReady] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [projectDetails, setProjectDetails] = useState<any>({});
  const [isShiftPress, setShiftPress] = useState(false);
  const [usedTimeStampArr, setUsedTimeStampArr] = useState<number[]>([]);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [speechToText, setSpeechToText] = useState<string[]>([]);
  const [adjectives, setAdjectives] = useState<string[]>([]);
  const [punctuation, setPunctuation] = useState<string[]>([]);
  const [emotionArr, setEmotionArr] = useState<any>([]);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(-1);
  const [visionAnalysisArr, setVisionAnalysisArr] = useState<any>([]);
  const [isBackToDashboard, setBackToDashboard] = useState(false);
  const [prevText, setPrevText] = useState([]);
  const [prevPunctuation, setPrevPunctuation] = useState([]);
  const handleSend = async e => {
    try {
      const date = new Date();
      setCurrentMessage("");
      setChats(prevChats => [
        ...prevChats,
        { message: e, role: "user", createAt: date.getTime() },
        { message: "", role: "ai", createAt: date.getTime() },
      ]);
      const res: any = await messageReq({
        chat: [
          ...chats,
          { message: e, role: "user", createAt: date.getTime() },
        ],
        id,
      });

      const message = JSON.parse(JSON.parse(res));

      setChats(prevChats => {
        const updatedChats = [...prevChats];
        updatedChats[updatedChats.length - 1] = {
          message: message.reply,
          role: "ai",
          createAt: date.getTime(),
          ts: message.ts,
        };
        return updatedChats;
      });
      setSendBtnDisabled(false);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  const handleBackClick = () => {
    videoRef?.current?.pause();
    setBackToDashboard(true);
  };
  useEffect(() => {
    if (isBackToDashboard && !videoPlayed) {
      if (window.history.length > 2) {
        navigate(-1);
      } else {
        navigate("/");
      }
      setBackToDashboard(false);
    }
  }, [isBackToDashboard]);
  const handleProjectReq = async () => {
    try {
      const response = await props.getProjectDetail(id);
      setProjectDetails(response);
      setLoading(false);
      setAudioReady(true);
      audioReq(true);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  useEffect(() => {
    handleProjectReq();
  }, []);
  const handlePlayVideo = e => {
    setCurrentTimeStamp(e.target.currentTime);
    if (videoPlayed) {
      videoRef.current.pause();
      setPrevText(prev => [...prev, ...speechToText]);
      setPrevPunctuation(prev => [...prev, ...punctuation]);
      setSpeechToText(prev => []);
      setPunctuation(prev => []);
    } else {
      videoRef.current.play();
      if (
        speechToText.length === 0 &&
        !usedTimeStampArr.includes(
          Number(
            Number(
              Object.keys(projectDetails?.speech_to_text?.data)?.[0]
            ).toFixed(0)
          )
        )
      ) {
        const firstTextData = Object.values(
          projectDetails?.speech_to_text?.data
        )[0] as any;

        if (firstTextData) {
          setSpeechToText(prev => [...prev, firstTextData?.text]);
          const punctuationText = firstTextData?.text?.replace(
            firstTextData.adjectives[0],
            `<span class="highlight-punctuation">${firstTextData.adjectives[0]}</span>`
          );
          setPunctuation(prev => [...prev, punctuationText]);
          if (firstTextData?.adjectives[0])
            setAdjectives(prev => [...prev, firstTextData.adjectives[0]]);
          setUsedTimeStampArr(prev => [
            ...prev,
            Number(
              Number(
                Object.keys(projectDetails?.speech_to_text?.data)?.[0]
              ).toFixed(0)
            ),
          ]);
        }
      }
    }
    setVideoPlayed(!videoPlayed);
  };
  const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    const currentTime = Number(e.currentTarget.currentTime.toFixed(0));
    if (usedTimeStampArr.includes(currentTime)) return;
    setUsedTimeStampArr([...usedTimeStampArr, currentTime]);
    const speechTimeStamp = Object.keys(
      projectDetails?.speech_to_text?.data
    ).map(timeStamp => Number(Number(timeStamp).toFixed(0)));
    const isIncludeTime = speechTimeStamp.includes(currentTime);

    if (isIncludeTime) {
      const speech: any = Object.entries(
        projectDetails?.speech_to_text?.data
      ).find(([timeStamp, speechobj]: [string, any], index: number) => {
        return Number(Number(timeStamp).toFixed(0)) === currentTime;
      });

      const emotionArr: any = Array.from(
        convertToTimestampFormat(projectDetails.report.emotion_analysis_llama)[
          currentTime
        ]
      );
      setEmotionArr(prev => [...prev, ...emotionArr]);
      setVisionAnalysisArr(emotionArr);

      if (speech && speech[1]) {
        setSpeechToText([...speechToText, speech[1].text]);
        const punctuationText = speech[1]?.text?.replace(
          speech[1].adjectives[0],
          `<span class="highlight-punctuation">${speech[1].adjectives[0]}</span>`
        );
        setPunctuation(prev => [...prev, punctuationText]);
        if (speech[1]?.adjectives[0])
          setAdjectives(prev => [...prev, speech[1].adjectives[0]]);
      }
    }
  };
  const handleJumpToTimeStamp = currentTime => {
    setEmotionArr(prev => (prev = []));
    setSpeechToText(prev => (prev = []));
    setPunctuation(prev => (prev = []));
    setUsedTimeStampArr(prev => (prev = []));
    setAdjectives(prev => (prev = []));
    setVideoPlayed(true);
    if (!projectDetails?.speech_to_text?.data) return;

    const roundedCurrentTime = Number(Number(currentTime).toFixed(1));

    const speech = Object.entries(projectDetails.speech_to_text.data)
      .filter(
        ([timeStamp]) =>
          Number(Number(timeStamp).toFixed(1)) < roundedCurrentTime
      )
      .map(([_, speechObj]) => {
        const { text, adjectives }: any = speechObj || {};
        const punctuationText = text?.replace(
          adjectives?.[0],
          `<span class="highlight-punctuation">${adjectives?.[0]}</span>`
        );
        return { text, punctuation: punctuationText };
      });

    setPrevText(speech.map(({ text }) => text));
    setPrevPunctuation(speech.map(({ punctuation }) => punctuation));
    setCurrentTimeStamp(roundedCurrentTime);
  };

  useEffect(() => {
    if (videoPlayed) {
      for (let i = 0; i < Number(currentTimeStamp); i++) {
        const currentTime = i;
        if (!usedTimeStampArr.includes(currentTime)) {
          setUsedTimeStampArr(prev => [...prev, currentTime]);
          const speechTimeStamp = Object.keys(
            projectDetails?.speech_to_text?.data
          ).map(timeStamp => Number(Number(timeStamp).toFixed(0)));
          const isIncludeTime = speechTimeStamp.includes(currentTime);

          if (isIncludeTime) {
            const speech: any = Object.entries(
              projectDetails?.speech_to_text?.data
            ).find(([timeStamp, speechobj]: [string, any], index: number) => {
              return Number(Number(timeStamp).toFixed(0)) === currentTime;
            });
            const emotionArr: any = Array.from(
              convertToTimestampFormat(
                projectDetails.report.emotion_analysis_llama
              )[currentTime]
            );
            setEmotionArr(prev => [...prev, ...emotionArr]);
            setVisionAnalysisArr(emotionArr);

            if (speech && speech[1]) {
              speechToText;
              punctuation;
              setSpeechToText(prev => [...prev, speech[1].text]);
              const punctuationText = speech[1]?.text?.replace(
                speech[1].adjectives[0],
                `<span class="highlight-punctuation">${speech[1].adjectives[0]}</span>`
              );
              setPunctuation(prev => [...prev, punctuationText]);
              [];
              if (speech[1]?.adjectives[0])
                setAdjectives(prev => [...prev, speech[1].adjectives[0]]);
            }
          }
        }
      }

      videoRef?.current?.pause();
      videoRef.current.currentTime =
        currentTimeStamp < 0 ? 0 : Number(currentTimeStamp);
      videoRef.current.play();
    }

    return () => {
      videoRef?.current?.pause();
    };
  }, [currentTimeStamp]);

  return (
    <>
      {isLoading || !isAudioReady ? (
        <Overlay blur={15} bg={"white"} center h={"100vh"}>
          <Loader variant="bars" color="#001e6b" />
        </Overlay>
      ) : null}
      {!isLoading && (
        <>
          <Box
            p={5}
            m={0}
            style={{
              textAlign: "left",
              backgroundColor: "#cfcdc8",
              overflow: `${isAudioReady ? "visible" : "hidden"}`,
            }}
            h={"100vh"}
          >
            <Grid p={0} m={0}>
              <Col p={0} py={4}>
                <Box>
                  <Flex align={"center"}>
                    <Box
                      style={{
                        maxWidth: "6.25rem",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        src={require("../../assets/images/logo.png")}
                        width={250}
                        height={80}
                      />
                    </Box>
                    <Box
                      py={10}
                      style={{
                        backgroundColor: "white",
                        height: "100%",
                        maxHeight: "100px",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Flex
                        h={74}
                        className="videoFrameFlex"
                        justify={"space-between"}
                      >
                        <Container
                          size={190}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginInline: "50px",
                            marginLeft: "10px",
                          }}
                          p={0}
                        >
                          <ActionIcon
                            className="back-btn"
                            onClick={() => {
                              setVideoPlayed(false);
                              handleBackClick();
                            }}
                          >
                            <ReplySharpIcon style={{ fill: "#001e6b" }} />
                          </ActionIcon>
                          <Text fw={600} ml={20}>
                            UNPROCESSED VIDEO
                          </Text>
                        </Container>

                        <CarouselFrame
                          img={projectDetails?.thumbnail?.files}
                          handlePlay={(e, timeStamp) => {
                            handleJumpToTimeStamp(timeStamp);
                          }}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Col>
            </Grid>
            <Grid p={0} m={0}>
              <Col span={6} p={0} pr={4} py={4} m={0}>
                <Box>
                  <Container bg={"#001e6b"} px={8} maw={"100%"}>
                    <Flex justify={"space-between"} align="center">
                      <Title order={6} color="white">
                        VIDEO
                      </Title>
                      <MenuIcon
                        style={{ marginRight: "0px", color: "white" }}
                      />
                    </Flex>
                  </Container>

                  <Box
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      height: "274px",
                    }}
                  >
                    <Flex>
                      <Box
                        p={10}
                        py={30}
                        style={{ width: "100%", maxWidth: "300px" }}
                      >
                        <Title order={3}>{projectDetails?.projectName}</Title>
                        <Box h={150} maw={200} w={"100%"}>
                          <Text>{projectDetails?.projectDescription}</Text>
                        </Box>
                      </Box>
                      <Flex
                        justify={"center"}
                        align={"center"}
                        pos={"relative"}
                        h={"274px"}
                        w={"470px"}
                      >
                        <video
                          id="video"
                          muted
                          crossOrigin="anonymous"
                          className="videoCntr"
                          ref={videoRef}
                          src={projectDetails?.videoUrl}
                          height={274}
                          width={470}
                          style={{
                            width: "100%",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                          onTimeUpdate={handleTimeUpdate}
                          onClick={handlePlayVideo}
                        />

                        {videoPlayed ? (
                          <Image
                            src={Pause}
                            classNames={{ root: "playBtn" }}
                            width={50}
                            height={50}
                            style={{ pointerEvents: "none" }}
                          ></Image>
                        ) : (
                          <Image
                            src={Play}
                            width={50}
                            height={50}
                            classNames={{ root: "pauseBtn" }}
                            style={{ pointerEvents: "none" }}
                          ></Image>
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              </Col>
              <Col span={6} p={0} pl={4} py={4} m={0}>
                <Box m={0} p={0}>
                  <Grid p={0} m={0}>
                    <Col span={6} p={0} pr={4} pb={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              TRANSLATION
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>

                        <div
                          style={{
                            width: "100%",
                            height: "120px",
                            paddingBlock: "5px",
                            overflow: "hidden",
                          }}
                          id="translation"
                        >
                          <TextTyped
                            isPlayed={videoPlayed}
                            timeStamp={currentTimeStamp}
                            text={speechToText}
                            videoDuration={videoRef?.current?.duration || 10}
                            prevText={prevText}
                          />
                        </div>
                      </Box>
                    </Col>
                    <Col span={6} p={0} pl={4} pb={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              PUNCTUATION
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box>
                          <div
                            style={{
                              width: "100%",
                              height: "120px",
                              paddingBlock: "5px",
                              overflow: "hidden",
                            }}
                            id="punctuation"
                          >
                            <PunctuationTyped
                              timeStamp={currentTimeStamp}
                              prevText={prevPunctuation}
                              text={punctuation}
                              isPlayed={videoPlayed}
                              videoDuration={videoRef?.current?.duration || 10}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Col>
                    <Col
                      span={6}
                      p={0}
                      pr={4}
                      pt={4}
                      m={0}
                      style={{ overflow: "hidden" }}
                    >
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              SENTIMENT
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box h={"100%"}>
                          <RoundChart
                            graphData={emotionArr || []}
                            rowGraphData={
                              Object.values(
                                convertToTimestampFormat(
                                  projectDetails?.report?.emotion_analysis_llama
                                )
                              ).flat() || []
                            }
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col span={6} p={0} pl={4} pt={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              &nbsp;&nbsp;ATTRIBUTE
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box
                          className="sentiment-parent"
                          style={{
                            height: "120.5px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            id="sentimentSection"
                            style={{
                              overflow: "scroll",
                              height: "120px",
                            }}
                          >
                            <Sentiment data={adjectives} />
                          </div>
                        </Box>
                      </Box>
                    </Col>
                  </Grid>
                </Box>
              </Col>
            </Grid>
            <Grid p={0} m={0}>
              <Col span={6} p={0} m={0} style={{ width: "100%" }}>
                <Grid p={0} m={0}>
                  <Col
                    span={5}
                    p={0}
                    pr={4}
                    pt={4}
                    m={0}
                    style={{ width: "100%" }}
                  >
                    <Box m={0} p={0}>
                      <Col
                        span={12}
                        p={0}
                        pb={4}
                        m={0}
                        style={{ width: "100%" }}
                      >
                        <Box
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                            maxHeight: "360px",
                          }}
                        >
                          <Box bg={"#001e6b"} px={7}>
                            <Flex justify={"space-between"} align="center">
                              <Title order={6} color="white">
                                OPINION SNIPPETS
                              </Title>
                              <MenuIcon
                                style={{ marginRight: "1px", color: "white" }}
                              />
                            </Flex>
                          </Box>
                          <Box
                            h={"100%"}
                            mah={"323px"}
                            display={"flex"}
                            style={{
                              justifyContent: "start",
                              alignItems: "end",
                              paddingInline: "5px",
                              overflow: "hidden",
                              height: "139px",
                            }}
                          >
                            <Carousel
                              withIndicators
                              height={120}
                              slideSize="10%"
                              slideGap="xs"
                              align="start"
                              slidesToScroll={1}
                              styles={{ root: { width: "24.1rem" } }}
                            >
                              {projectDetails?.thumbnail?.files &&
                                projectDetails?.report.opinion_snippet
                                  ?.filter(
                                    isHaveThumbnail =>
                                      isHaveThumbnail?.thumbnail
                                  )
                                  .map((thumbnail: any, ind) => {
                                    return (
                                      <Carousel.Slide key={ind}>
                                        <div>
                                          <img
                                            src={thumbnail?.thumbnail}
                                            style={{
                                              width: "145px",
                                              height: "100px",
                                            }}
                                          />
                                        </div>
                                      </Carousel.Slide>
                                    );
                                  })}
                            </Carousel>
                          </Box>
                        </Box>
                      </Col>
                      <Col span={12} p={0} pt={4} m={0}>
                        <Box
                          style={{
                            backgroundColor: "white",

                            height: "157px",
                          }}
                        >
                          <Box bg={"#001e6b"} px={7}>
                            <Flex justify={"space-between"} align="center">
                              <Title order={6} color="white">
                                VOICE MODULATION
                              </Title>
                              <MenuIcon
                                style={{ marginRight: "1px", color: "white" }}
                              />
                            </Flex>
                          </Box>
                          <Wave
                            path={projectDetails?.audio_url}
                            isPlay={videoPlayed}
                            timeStamp={currentTimeStamp}
                          />
                        </Box>
                      </Col>
                    </Box>
                  </Col>
                  <Col span={7} p={0} px={4} pt={4} m={0}>
                    <Box
                      style={{
                        backgroundColor: "white",
                        height: "100%",
                        maxHeight: "352px",
                      }}
                    >
                      <Box bg={"#001e6b"} px={7}>
                        <Flex justify={"space-between"} align="center">
                          <Title order={6} color="white">
                            COMPUTER VISION ANALYSIS
                          </Title>
                          <MenuIcon
                            style={{ marginRight: "1px", color: "white" }}
                          />
                        </Flex>
                      </Box>
                      <Box h={"100%"}>
                        <VisionAnalysis
                          path={projectDetails?.videoUrl}
                          timeStamp={currentTimeStamp}
                          isPlay={videoRef?.current?.paused}
                          emotion={visionAnalysisArr}
                        />
                      </Box>
                    </Box>
                  </Col>
                </Grid>
              </Col>
              <Col span={6} p={0} py={4} pl={4} m={0} style={{ width: "100%" }}>
                <Box
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    maxHeight: "352px",
                  }}
                >
                  <Box bg={"#001e6b"} px={7}>
                    <Flex justify={"space-between"} align="center">
                      <Title order={6} color="white">
                        TRUE VALUE (TV)
                      </Title>
                      <MenuIcon
                        style={{ marginRight: "1px", color: "white" }}
                      />
                    </Flex>
                  </Box>
                  <Box
                    h={"100%"}
                    mah={"323px"}
                    display={"flex"}
                    style={{
                      justifyContent: "start",
                      alignItems: "end",
                      paddingBottom: "4px",
                      paddingLeft: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Graph
                      data={transformDataToGraphFormat(
                        projectDetails?.true_value_score
                      )}
                    />
                  </Box>
                </Box>
              </Col>
            </Grid>
          </Box>
          <Dialog
            w={550}
            opened={open}
            onClose={() => setOpen(false)}
            withCloseButton
            transition={"slide-left"}
            styles={{
              root: {
                borderRadius: "22px",
                overflow: "hidden",
              },
              closeButton: {
                right: "2px",
                top: "2px",
              },
            }}
          >
            <Container
              size="sm"
              style={{
                height: "calc(100vh - 80px)",
                paddingTop: "20px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  backgroundColor: "#f5f5f5",
                  top: "0px",
                  left: "0px",
                  height: "calc(92vh - 100px)",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column-reverse",
                  marginBottom: "15px",
                  paddingBottom: "7px",
                  overflowY: "auto",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                ref={el => {
                  if (el) {
                    el.scrollTop = el.scrollHeight;
                  }
                }}
              >
                {chats
                  .slice()
                  .reverse()
                  .map((val, ind) => (
                    <Fragment key={ind}>
                      {val?.role === "ai" && val.message === "" ? (
                        <Skeleton height={40} m={7} width="66%" radius="sm" />
                      ) : (
                        <>
                          <Box>
                            <Box
                              className={`${
                                val?.role !== "user"
                                  ? "ai-message-box"
                                  : "user-message-box"
                              }`}
                              style={{
                                float: val?.role !== "user" ? "left" : "right",
                                margin: "7px",
                                wordBreak: "break-word",
                                height: "auto",
                                minHeight: "20px",
                                maxWidth: "66%",
                                padding: "10px",
                                borderRadius: "7px",
                                fontSize: "0.9rem",
                                lineHeight: "1.1rem",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {val.message}
                              {val?.role === "ai" && (
                                <Flex
                                  className="ai-jump-video-contr"
                                  w={"100%"}
                                  h={"100%"}
                                  justify={"left"}
                                  align={"end"}
                                  pl={20}
                                  pb={10}
                                  style={{ cursor: "pointer" }}
                                  onClick={e => {
                                    videoRef.current.pause();
                                    videoRef.current.currentTime = val.ts;
                                    videoRef.current.play();
                                  }}
                                >
                                  <Text>
                                    {`Jump to video at ${convertToMinutesAndSeconds(
                                      Number(val.ts.toFixed(0))
                                    )}`}
                                  </Text>
                                </Flex>
                              )}
                            </Box>
                            {chats.length > 0 &&
                              chats[chats.length - 1]?.role === "ai" &&
                              chats[chats.length - 1]?.message ===
                                "Sorry, am missing some data regarding this. Please ask something else related to the video." &&
                              ind == 0 && (
                                <Box
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "10px 20px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    gap: "10px",
                                    marginBottom: "-7px",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {projectDetails?.questions?.map(
                                    (suggestion, index) => (
                                      <Tooltip
                                        key={index}
                                        multiline
                                        w={250}
                                        withArrow
                                        transitionProps={{ duration: 200 }}
                                        label={suggestion}
                                      >
                                        <Button
                                          variant="outline"
                                          size="xs"
                                          style={{
                                            borderRadius: "20px",
                                            padding: "5px 10px",
                                            fontSize: "0.85rem",
                                            cursor: "pointer",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "150px",
                                            color: "#001e6b",
                                            borderColor: "#001e6b",
                                            textAlign: "center",
                                          }}
                                          onClick={() => handleSend(suggestion)}
                                        >
                                          <Text
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              fontSize: "0.85rem",
                                            }}
                                          >
                                            {suggestion}
                                          </Text>
                                        </Button>
                                      </Tooltip>
                                    )
                                  )}
                                </Box>
                              )}
                          </Box>
                        </>
                      )}
                    </Fragment>
                  ))}

                {chats.length <= 0 && (
                  <Box
                    style={{
                      position: "relative",
                      marginBottom: "-7px",
                      width: "100%",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {projectDetails?.questions?.map((suggestion, index) => (
                      <Tooltip
                        key={index}
                        multiline
                        w={250}
                        withArrow
                        transitionProps={{ duration: 200 }}
                        label={suggestion}
                      >
                        <Button
                          variant="outline"
                          size="xs"
                          style={{
                            borderRadius: "20px",
                            padding: "5px 10px",
                            fontSize: "0.85rem",
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "150px",
                            color: "#001e6b",
                            borderColor: "#001e6b",
                            textAlign: "center",
                          }}
                          onClick={() => handleSend(suggestion)}
                        >
                          <Text
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontSize: "0.85rem",
                            }}
                          >
                            {suggestion}
                          </Text>
                        </Button>
                      </Tooltip>
                    ))}
                  </Box>
                )}
              </Box>
              <Box
                style={{
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bottom: "0",
                  left: "0",
                  width: "100%",
                  backgroundColor: "#001e6b",
                  padding: "20px",
                  boxShadow:
                    "0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24)",
                }}
              >
                <Textarea
                  placeholder="Generate message..."
                  minRows={1}
                  value={currentMessage}
                  autosize={false}
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                    marginRight: "5px",
                  }}
                  onChange={e => setCurrentMessage(e.target.value)}
                  onKeyUp={e => {
                    if (e.key === "Shift") {
                      setShiftPress(false);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === "Shift") {
                      setShiftPress(true);
                    }
                    if (e.key === "Enter" && !isShiftPress) {
                      e.preventDefault();
                      if (!sendBtnDisabled) {
                        handleSend(currentMessage);
                        setSendBtnDisabled(true);
                      }
                    }
                  }}
                />
                <Group position="right">
                  <Button
                    variant="light"
                    style={{
                      height: "44px",
                      marginBottom: "10px",
                      color: "#001e6b",
                    }}
                    onClick={e => {
                      if (!sendBtnDisabled) {
                        handleSend(currentMessage);
                        setSendBtnDisabled(true);
                      }
                    }}
                  >
                    Send
                  </Button>
                </Group>
              </Box>
            </Container>
          </Dialog>
          {projectDetails?.isSaveEmbeddings && (
            <ActionIcon
              style={{
                position: "absolute",
                right: "30px",
                bottom: "30px",
              }}
              onClick={() => setOpen(true)}
            >
              <MessageRounded
                style={{ width: "60px", height: "70px", fill: "#001e6b" }}
              />
            </ActionIcon>
          )}
        </>
      )}
    </>
  );
}

export default ProjectDetail;
