import React, { useEffect, useRef, useState } from "react";
import "./style.css";
interface props {
  path: string;
  timeStamp: any;
  emotion?: string[];
  click?: any;
  isPlay?: boolean;
}

const VisionAnalysis = ({
  path,
  timeStamp = 0,
  emotion = [],
  isPlay = false,
}: props) => {
  const videoRef = useRef() as any | HTMLVideoElement;
  useEffect(() => {
    videoRef.current.pause();
    videoRef.current.currentTime = timeStamp;
    if (!isPlay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }

    return () => {
      videoRef?.current?.pause();
    };
  }, [timeStamp, isPlay]);
  const filteredEmotion = Array.from(new Set(emotion));

  return (
    <div
      className="visionVideoContr"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: "50px",
      }}
    >
      <video src={path} muted ref={videoRef} width={400} height={300}></video>
      <div className="visionVideoOverlay">
        {
          <div
            style={{
              border: "2px solid green",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              height: "150px",
              width: "250px",
              position: "relative",
            }}
          >
            <p
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "14px",
                backgroundColor: "rgba(100, 100, 100, 0.6)",
                width: "fit-content",
                margin: "5px",
                paddingInline: "5px",
                fontWeight: "bold",
                borderRadius: "5px",
                top: "0",
                right: "0",
                position: "absolute",
              }}
            >
              {filteredEmotion.join(" / ").toUpperCase()}
            </p>
          </div>
        }
      </div>
    </div>
  );
};

export default VisionAnalysis;
