/** @format */

import { API_CONFIG } from '../../../constant/constant';
import httpService from '../../../http/http';

export const getProjectReq = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(API_CONFIG.path.project, { id: id })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};

export const messageReq = (body) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(API_CONFIG.path.chat, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error : ', error);
        reject(error);
      });
  });
};


export const getResponsesDeatilsReq = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.feedbackResDetails}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};