import { Box, Card } from "@mantine/core";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const Dashboard = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleProjectClick = () => {
    navigate("/instavidiq"); // Redirect to the project page
  };

  const handleFeedbackClick = () => {
    navigate("/surveyCine"); // Redirect to the feedback page
  };

  return (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{ textAlign: "center", minHeight: "calc(100vh - 185px)" }}
    >
      <h2 style={{ fontSize: "35px" }}>Dashboard</h2>

      <Box
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 50,
          marginTop: "25px",
        }}
      >
        <div
          onClick={handleProjectClick}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            height: "200px",
            marginBottom: "20px",
            borderRadius: "8px",
            cursor: "pointer",
            fontSize: "24px",
            fontWeight: "bold",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            width: "25%",
          }}
        >
          InstaVidIQ
        </div>

        <div
          onClick={handleFeedbackClick}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            height: "200px",
            marginBottom: "20px",
            borderRadius: "8px",
            cursor: "pointer",
            fontSize: "24px",
            fontWeight: "bold",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            width: "25%",
          }}
        >
          SurveyCine
        </div>
      </Box>
    </Card>
  );
};

export default Dashboard;
