/* eslint-disable react/prop-types */

import { emotionBreakdownForGraph } from "../../feature/projectDetail/helpers/functions";
import { ResponsiveBar } from "@nivo/bar";
import pdfStyle from "./pdf.module.css";

const EmotionGraph = ({
  emotion,
  title = "Emotion Graph",
  isPercentage = false,
  yLabel = "",
}: {
  emotion: any;
  title?: string;
  isPercentage?: boolean;
  yLabel?: string;
}) => {
  const emotionGraph = !emotion[0]?.count
    ? emotionBreakdownForGraph(emotion).map(val => ({
        ...val,
        emotion: String(val.emotion),
      }))
    : emotion;
  const maxLength = emotionGraph.reduce(
    (max, val) => Math.max(max, val.emotion.length),
    0
  );

  const height = 50 + maxLength * 7;
  return (
    <>
      <div
        style={{ width: "100%", paddingBlock: "40px", paddingBottom: "100px" }}
      >
        <div className={pdfStyle.titleContr}>
          <h1>
            <u>{title}</u>
          </h1>
        </div>
        <div style={{ width: "790px", height: "350px" }}>
          <ResponsiveBar
            colorBy="id"
            data={emotionGraph}
            keys={["count"]}
            indexBy="emotion"
            margin={{ top: 50, right: 50, bottom: height - 50, left: 60 }} // Use calculated height
            padding={0.3}
            layout="vertical"
            valueScale={{ type: "linear" }}
            colors={["#9730e1"]}
            borderColor={{ from: "color", modifiers: [["darker", 0]] }}
            enableGridX={false}
            enableGridY={false}
            axisBottom={{
              tickSize: 5,
              renderTick: propc => {
                return (
                  <text
                    x={propc.x}
                    y={propc.y + 20}
                    fill="#ffffff"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    transform={`rotate(290, ${propc.x + height / 2.5 - 50}, ${
                      propc.y + height / 2.5 - 30
                    })`}
                    fontSize={13}
                  >
                    {propc.value}
                  </text>
                );
              },
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: [yLabel],
              legendPosition: "middle",
              legendOffset: -40,
              renderTick: propc => {
                return (
                  <text
                    x={propc.x - 15}
                    y={propc.y}
                    fill="#ffffff"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={13}
                  >
                    {propc.value}
                    {isPercentage ? "%" : ""}
                  </text>
                );
              },
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 0.5]] }}
            animate={true}
          />
        </div>
      </div>
    </>
  );
};

export default EmotionGraph;
