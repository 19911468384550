/** @format */

import { API_CONFIG, getUrl } from "../../../constant/constant";
import httpService from "../../../http/http";

export const getListReq = (pageSize, page) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.list}?pageSize=${pageSize}&page=${page}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = "/login";
        } else {
          reject(error);
        }
      });
  });
};

export const getReportReq = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.report}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getResponsesReportReq = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.responsesReport}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const searchFromList = (search: any, pageSize: number, page: number) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        `${API_CONFIG.path.list}/${search}?pageSize=${pageSize}&page=${page}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const deleteProjectReq = (id: any) => {
  return new Promise((resolve, reject) => {
    httpService
      .deleteRequest(`${API_CONFIG.path.project}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const createProjectReq = (formData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.project,
        formData,
        {},
        { contentType: "multipart/form-data;" }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const createFeedbackReq = (formData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.feedback,
        formData,
        {},
        { contentType: "multipart/form-data;" }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const getFeedbackListReq = (
  pageSize: number,
  page: number,
  searchQuery?: string
) => {
  return new Promise((resolve, reject) => {
    const searchParam = searchQuery
      ? `&search=${encodeURIComponent(searchQuery)}`
      : "";
    httpService
      .get(
        `${API_CONFIG.path.feedbacklist}?pageSize=${pageSize}&page=${page}${searchParam}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = "/login";
        } else {
          reject(error);
        }
      });
  });
};


export const getFeedbackDeleteReq = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .deleteRequest(`${API_CONFIG.path.feedbackdel}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const closeFeedbackReq = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(`${API_CONFIG.path.feedbackclose}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const getFeedbackResponsesListReq = (
  id: any,
  pageSize: number,
  page: number
) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        `${API_CONFIG.path.feedbackResponseList}/${id}?pageSize=${pageSize}&page=${page}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const responsesDeleteReq = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .deleteRequest(`${API_CONFIG.path.responsedel}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};