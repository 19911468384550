import { API_CONFIG, getUrl } from "../../../constant/constant";
import httpService from "../../../http/http";

export const getQuestionsList = (id: any, pageSize: number, page: number) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        `${API_CONFIG.path.questionList}/${id}?pageSize=${pageSize}&page=${page}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQuestionReport = async (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.questioneport}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
