import React, { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Card, Container, Text } from "@mantine/core";
import {
  addFeedbackResponse,
  addFeedbackResponseInfo,
  getFeedbackDetails,
} from "./service/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "@mantine/carousel";
import { feedbackResponseValidationSchema } from "../../constant/validation";
import "./styles/style.css";

const NonLoggedFeedback = () => {
  const { feedbackId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [questions, setQuestions] = useState<any>([]);
  const [feedbackDetails, setFeedbackDetails] = useState<any>([]);
  const [recordId, setRecordId] = useState<any>("");
  const [responseForm, setResponseForm] = useState({
    feedbackId,
    name: "",
    contactInfo: "",
    feedbackFile: null,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: responseForm,
    enableReinitialize: true,
    validationSchema: feedbackResponseValidationSchema,
    onSubmit: () => resetForm(),
  });

  const handleNextStep = async () => {
    try {
      if (currentStep === 0) {
        const response: any = await addFeedbackResponseInfo({
          name: values.name,
          feedbackId,
          contactInfo: values.contactInfo,
        });
        if (response) {
          setQuestions(response.questions);
          const trueCount = response?.questions?.filter(
            item => item.isResponded === true
          ).length;
          setRecordId(response._id);
          if (trueCount === response.questions?.length) {
            setSubmitting(true);
          } else {
            setCurrentStep(1 + trueCount);
          }
        }
      } else if (currentStep <= questions.length && recordId) {
        const questionId = questions[currentStep - 1]?._id;
        const formData = new FormData();
        formData.append("recordId", recordId);
        formData.append("questionId", questionId);
        formData.append("responseFile", values.feedbackFile || "");
        const response = await addFeedbackResponse(formData);
        if (response) {
          setFieldValue("feedbackFile", null);
          setCurrentStep(currentStep + 1);
          if (currentStep === questions.length) setSubmitting(true);
        }
      }
    } catch (error) {
      console.error("Error during feedback response", error);
      toast.error("Error while submitting your response. Please try again");
    }
  };

  useEffect(() => {
    const fetchFeedbackDetails = async () => {
      try {
        const response = await getFeedbackDetails(feedbackId);
        setFeedbackDetails(response);
      } catch (error) {
        console.error("Error fetching feedback details", error);
      }
    };
    fetchFeedbackDetails();
  }, [feedbackId]);

  return (
    <Box style={{ position: "relative", padding: "10px" }}>
      <img
        src={require("../../assets/images/logo.png")}
        alt="logo"
        className="logo"
        style={{
          position: "absolute",
          top: "2px",
          left: "2px",
          width: "100px",
        }}
      />
      <div className="feedback-section">
        <div className="feedback-heading">
          <h2>Share SurveyCine</h2>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {!isSubmitting ? (
            <div className="feedback-details-container">
              <div className="heading-contr">
                <Text ta="left" fw={700} size="xl" style={{ fontSize: "28px" }}>
                  {currentStep === 0 ? "Your Details" : "SurveyCine Question"}
                </Text>
              </div>
              {currentStep === 0 ? (
                <>
                  <div
                    style={{
                      padding: "20px",
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Text size="md" mt="sm" fw={550}>
                        Title:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {feedbackDetails.title}
                        </span>
                      </Text>
                      <Text size="md" mt="sm" fw={550}>
                        Description:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {feedbackDetails.description}
                        </span>
                      </Text>
                    </div>
                    <Text size="md" mt="sm" fw={550}>
                      End Date:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {new Date(feedbackDetails.endDate).toLocaleDateString()}
                      </span>
                    </Text>
                  </div>
                  {feedbackDetails?.attachments?.length > 0 && (
                    <Carousel loop withControls w="auto" h="auto">
                      {feedbackDetails.attachments.map(
                        (attachment: string, index: number) => {
                          const isVideo = attachment.endsWith(".mp4");
                          return (
                            <Carousel.Slide key={index}>
                              <div className="attachment-item">
                                {isVideo ? (
                                  <video controls className="video-attachment">
                                    <source src={attachment} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={attachment}
                                    alt={`attachment-${index}`}
                                    className="image-attachment"
                                  />
                                )}
                              </div>
                            </Carousel.Slide>
                          );
                        }
                      )}
                    </Carousel>
                  )}
                  <form
                    onSubmit={e => e.preventDefault()}
                    className="feedback-form"
                  >
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {touched.name && errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="contactInfo">Contact Info</label>
                      <input
                        type="text"
                        id="contactInfo"
                        name="contactInfo"
                        placeholder="Enter your contact info"
                        onChange={handleChange}
                        value={values.contactInfo}
                      />
                      {touched.contactInfo && errors.contactInfo && (
                        <div className="error">{errors.contactInfo}</div>
                      )}
                    </div>
                    <div className="form-actions">
                      <button
                        type="button"
                        className={
                          values.name &&
                          values.contactInfo &&
                          !errors.name &&
                          !errors.contactInfo
                            ? "submit-button"
                            : "submitdisable-button"
                        }
                        onClick={
                          !errors.contactInfo && !errors.name
                            ? handleNextStep
                            : undefined
                        }
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <form className="feedback-form" onSubmit={handleSubmit}>
                  {questions.map((_, index) => {
                    return (
                      <Fragment key={index}>
                        {index === currentStep - 1 && (
                          <fieldset className="form-fieldset">
                            <Text className="heading">
                              Question {currentStep}:{" "}
                              {questions[currentStep]?.question}
                            </Text>
                            <div className="form-group file-upload-group">
                              <label
                                htmlFor="feedbackFile"
                                className="upload-label"
                              >
                                <span className="upload-button">
                                  Record Opinion
                                </span>
                                <span className="file-name">
                                  {values.feedbackFile?.name || ""}
                                </span>
                              </label>
                              <input
                                type="file"
                                id="feedbackFile"
                                name="feedbackFile"
                                accept="video/*"
                                className="file-input"
                                capture="environment"
                                onChange={e =>
                                  setFieldValue(
                                    "feedbackFile",
                                    e.target.files?.[0]
                                  )
                                }
                              />
                            </div>
                            <div className="form-actions">
                              <button
                                type="button"
                                className="submit-button"
                                onClick={handleNextStep}
                              >
                                {currentStep === questions.length
                                  ? "Submit"
                                  : "Next"}
                              </button>
                            </div>
                          </fieldset>
                        )}
                      </Fragment>
                    );
                  })}
                </form>
              )}
            </div>
          ) : (
            <Card
              style={{
                width: "100%",
                textAlign: "center",
                borderRadius: "10px",
              }}
            >
              <Text
                ta="center"
                fw={700}
                size="xl"
                color="green"
                style={{ fontSize: "36px" }}
              >
                Thank You!
              </Text>
              <Text ta="center" size="md" style={{ marginTop: 10 }}>
                Your Feedback has been submitted successfully
              </Text>
            </Card>
          )}
        </div>
      </div>
    </Box>
  );
};

export default NonLoggedFeedback;
