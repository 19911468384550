import { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Grid,
  Col,
  Text,
  Box,
  Group,
  Center,
  Loader,
} from "@mantine/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router-dom";
import { getQuestionsList } from "./service/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Questions = () => {
  const navigate = useNavigate();
  const [questions, setQuestion] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const itemsPerPage = 10;
  const currentPageRef = useRef<number>();
  const { id } = useParams();
  let interval;
  useEffect(() => {
    getQuestionsList(id, itemsPerPage, currentPage)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        setLoading(false);
        setQuestion(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          localStorage.remove();
          navigate("/login");
        } else {
          toast.error(err.message);
          setQuestion([]);
        }
      });
  }, [currentPage, update, navigate]);
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);
  const handleRefresh = () => {
    getQuestionsList(id, itemsPerPage, currentPage)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        setLoading(false);
        setQuestion(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          localStorage.remove();
          navigate("/login");
        } else {
          toast.error(err.message);
          setQuestion([]);
        }
      });
  };
  useEffect(() => {
    interval = setInterval(() => handleRefresh(), 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Card.Section withBorder inheritPadding py="xs">
          {" "}
          <Box
            style={{
              width: "100%",
              marginTop: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="white"
              style={{
                fontSize: "20px",
                padding: "0",
              }}
              leftIcon={<ArrowBackIosNewIcon />}
              onClick={() => navigate(`/surveyCine`)}
            >
              Back
            </Button>
          </Box>
        </Card.Section>
        {isLoading ? (
          <Box
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Center style={{ minHeight: "calc(100vh - 386px)" }}>
              <Loader variant="bars" />
            </Center>
          </Box>
        ) : (
          <Box
            mt={10}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "15px",
              flexWrap: "wrap",
            }}
          >
            {questions && questions.length > 0 ? (
              questions.map((question, index) => (
                <Box key={question.id}>
                  <div
                    className="grid-item"
                    style={{
                      position: "relative",
                      minHeight: "150px",
                      minWidth: "400px",
                      width: "100%",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "8px",
                      overflow: "hidden",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <Box
                      display={"flex"}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box>
                        {" "}
                        <Text mx={10} my={5} style={{ fontWeight: 600 }}>
                          Question {index + 1}
                        </Text>
                        <Text mx={10} my={5}>
                          {question.question}
                        </Text>
                      </Box>
                      <Text
                        style={{
                          color: `${
                            question.status === "Completed" &&
                            question?.isSaveEmbeddings
                              ? "green"
                              : question.status === "Failed" &&
                                !question?.isSaveEmbeddings
                              ? "red"
                              : "rgb(0, 30, 107)"
                          }`,
                          padding: "10px 20px",
                        }}
                      >
                        {question.status || "-"}
                      </Text>
                    </Box>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <Text mx={10} style={{ fontWeight: 600 }} mt={14} mb={5}>
                        Response Count: {question.responsesCount}
                      </Text>
                      <div
                        className="button-container"
                        style={{
                          opacity: 1,
                          transition: "opacity 0.3s ease",
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <Button
                          title="View Responses"
                          variant="filled"
                          color="light"
                          disabled={question.responsesCount == 0}
                          onClick={() => {
                            navigate(`/surveyCine/question/${question._id}`);
                          }}
                        >
                          <Text>View</Text>
                        </Button>
                        <Button
                          title="Generate PDF Report"
                          variant="filled"
                          color="cyan"
                          disabled={question.status !== "pending"}
                          onClick={() => {
                            navigate(
                              `/surveyCine/question/report/${question._id}`
                            );
                          }}
                        >
                          <Text>View Report</Text>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              ))
            ) : (
              <Text>No Questions Available</Text>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

export default Questions;
