/** @format */

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import Dashboard from "./feature/dashboard/dashboard";
import ProjectDetail from "./feature/projectDetail/projectDetail";

import Layout from "./feature/hoc/layout";
import { useDispatch, useSelector } from "react-redux";
import ProjectReport from "./component/pdf/projectReport";
import Project from "./feature/project/project";
import Feedback from "./feature/feedback/feedback";
import NonLoggedFeedback from "./feature/feedback/nonLoggedFeedback";
import Response from "./feature/feedbackResponse/response";
import FeedbackReport from "./component/pdf/feedbackReport";
import Login from "./feature/auth/login";
import Signup from "./feature/auth/signup";
import ResetPasswordPage from "./feature/auth/reset-password";
import ForgotPassword from "./feature/auth/forgot-password";

import { refreshAccessToken } from "./feature/auth/service/service";
import { useEffect } from "react";
import { loginAction } from "./feature/auth/redux/action";
import Questions from "./feature/questions/questions";
import QuestionReport from "./component/pdf/questionReport";
import {
  getProjectReq,
  getResponseDetail,
} from "./feature/projectDetail/service/service";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state: any) => state.loginReducer.isLogin);
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  const checkAndRefreshToken = async () => {
    if (refreshToken) {
      try {
        const res: any = await refreshAccessToken(refreshToken);
        localStorage.setItem("access_token", res.accessToken);
        localStorage.setItem("access_token", res.accessToken);
        dispatch(loginAction(true));
      } catch (err) {
        localStorage.clear();
        console.log("Session expired. Please log in again.");
        dispatch(loginAction(false));
      }
    } else {
      dispatch(loginAction(false));
      console.log("No tokens found. Please log in.");
    }
  };
  useEffect(() => {
    if (!accessToken) {
      checkAndRefreshToken();
    }
  }, []);
  const router = () => {
    if (isLogin) {
      return (
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Layout />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/instavidiq" element={<Layout />}>
            <Route index element={<Project />} />
          </Route>
          <Route path="/report/:id" element={<ProjectReport />} />
          <Route element={<ProjectDetail getProjectDetail={getProjectReq} />} />
          <Route path="/surveyCine" element={<Layout />}>
            <Route index element={<Feedback />} />
          </Route>
          <Route path="/surveyCine/report/:id" element={<FeedbackReport />} />
          <Route path="/surveyCine/:id" element={<Layout />}>
            <Route index element={<Questions />} />
          </Route>
          <Route
            path="/surveyCine/question/report/:id"
            element={<QuestionReport />}
          />
          <Route path="/surveyCine/question/:id" element={<Layout />}>
            <Route index element={<Response />} />
          </Route>
          <Route
            path="/surveyCine/question/response"
            element={<ProjectDetail getProjectDetail={getResponseDetail} />}
          />
          <Route
            path="/surveyCine/response/report/:id"
            element={<ProjectReport />}
          />

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      );
    } else {
      // lazy loading not needed for login, forget password, reset password APIs
      return (
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/surveyCine/:feedbackId"
            element={<NonLoggedFeedback />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      );
    }
  };

  return <BrowserRouter>{router()}</BrowserRouter>;
}
export default App;
