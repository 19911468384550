/** @format */

import {
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Container,
  Space,
  Text,
  TextInput,
  Checkbox,
  useMantineTheme,
} from "@mantine/core";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import { useState } from "react";
import { signupSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { postSignupReq } from "./service/service";
import { LoginAction } from "./redux";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
const initialValue = {
  name: "",
  email: "",
  mobileNo: "",
  password: "",
  confirmPassword: "",
  agreeWithTermsAndConditions: false,
};

export default function signup() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { loginHandleAction } = bindActionCreators(LoginAction, dispatch);
  const { errors, touched, handleBlur, handleChange, handleSubmit, values } =
    useFormik({
      initialValues: initialValue,
      validationSchema: signupSchema,
      onSubmit: (e) => {
        // const signupData = {
        //   name: e.name,
        //   email: e.email,
        //   mobileNo: e.mobileNo,
        //   password: e.password,
        //   agreeWithTermsAndConditions: e.agreeWithTermsAndConditions,
        // };
        // postSignupReq(signupData)
        //   .then((res: any) => {
        //     navigate("/dashboard");
        //     localStorage.setItem("access_token", res.accessToken);
        //     localStorage.setItem("refresh_token", res.refreshToken);
        //     loginHandleAction(true);
        //   })
        //   .catch((err) => {
        //     setError(err.response.data.message);
        //   });
        toast.info("Will be available soon");
      },
    });

  return (
    <Box>
      <Center style={{ width: "100%", height: "100vh" }}>
        <Container p={10} style={{ width: "100%", maxWidth: "400px" }}>
          <Card
            radius={10}
            shadow={"lg"}
            style={{
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            pb={30}
            px={30}
          >
            <Box
              my={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Center>
                <img src={logo} alt="logo" className="logo" />
              </Center>
              <Center mt={10}>
                <Text size={25} color={theme.colors.blue[4]} weight={700}>
                  Sign Up
                </Text>
              </Center>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextInput
                my={7}
                mx={10}
                name="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Name
                  </p>
                }
                error={touched.name && errors.name ? errors.name : null}
              />

              <TextInput
                my={7}
                mx={10}
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Email
                  </p>
                }
                error={touched.email && errors.email ? errors.email : null}
              />

              <TextInput
                my={7}
                mx={10}
                name="mobileNo"
                value={values.mobileNo}
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Mobile Number
                  </p>
                }
                error={
                  touched.mobileNo && errors.mobileNo ? errors.mobileNo : null
                }
              />

              <TextInput
                my={7}
                mx={10}
                value={values.password}
                type={isShowPassword ? "text" : "password"}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Password
                  </p>
                }
                error={
                  touched.password && errors.password ? errors.password : null
                }
                rightSection={
                  !isShowPassword ? (
                    <VisibilityOffIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[6]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[2]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowPassword((prev) => !prev)}
                    />
                  )
                }
              />

              <TextInput
                my={7}
                mx={10}
                value={values.confirmPassword}
                type={isShowConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Confirm Password
                  </p>
                }
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : null
                }
                rightSection={
                  !isShowConfirmPassword ? (
                    <VisibilityOffIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[6]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowConfirmPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[2]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowConfirmPassword((prev) => !prev)}
                    />
                  )
                }
              />

              <Checkbox
                mx={10}
                my={10}
                label="I agree to the Terms and Conditions"
                name="agreeWithTermsAndConditions"
                checked={values.agreeWithTermsAndConditions}
                onChange={handleChange}
                error={
                  touched.agreeWithTermsAndConditions &&
                  errors.agreeWithTermsAndConditions
                    ? errors.agreeWithTermsAndConditions
                    : null
                }
              />

              {error !== "" && (
                <Center>
                  <Text color="red">{error}</Text>
                </Center>
              )}

              <Center mt={10} mx={5} mb={20}>
                <Button
                  fullWidth
                  type="submit"
                  variant="filled"
                  style={{ fontSize: "18px" }}
                >
                  Sign Up
                </Button>
              </Center>

              <Center>
                <Anchor href="/login" variant="link" color="blue">
                  Already have an account? <Anchor>Login</Anchor>
                </Anchor>
              </Center>
            </form>
          </Card>
        </Container>
      </Center>
    </Box>
  );
}
