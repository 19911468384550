/** @format */

import {
  Burger,
  Header,
  MediaQuery,
  MantineTheme,
  Box,
  Button,
} from "@mantine/core";

import { useNavigate } from "react-router-dom";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../auth/redux";


interface Props {
  open: boolean;
  theme: MantineTheme;
  toggleSidenav: (open: boolean) => void;
}

export default function TopHeader(props: Props) {
  const navigate = useNavigate();
  const { open, theme, toggleSidenav } = props;
  const dispatch = useDispatch();
  const { loginHandleAction } = bindActionCreators(LoginAction, dispatch);
  const isLogin = useSelector((state: any) => state.loginReducer.isLogin);
  return (
    <Header height={70} p="md">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={open}
              onClick={() => toggleSidenav(!open)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
          <Box>
            <div className="flex justify-center align-center">
              <h3>AI Emotion</h3>
            </div>
          </Box>
        </div>
        {isLogin ? (
          <Button
            variant="filled"
            onClick={() => {
              loginHandleAction(false);
              localStorage.clear();
              navigate("/");
            }}
          >
            Logout
          </Button>
        ) : (
          <Button
            variant="filled"
            onClick={() => {
              navigate("/login");
            }}
          >
            Log In
          </Button>
        )}
      </div>
    </Header>
  );
}
