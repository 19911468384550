/** @format */

import { useEffect, useState } from "react";

import WaveUi from "./waveUi";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { projectActions } from "../../feature/projectDetail/redux";

interface props {
  path?: string;
  isPlay?: boolean;
  timeStamp?: number;
  click?: any;
}

export default function Wave({ path, isPlay, timeStamp, click }: props) {
  const [analyzerData, setAnalyzerData] = useState(null);
  const [audio, setAudio] = useState(new Audio());

  const [isFirstTime, setFirstTime] = useState(true);
  const dispatch = useDispatch();
  const { audioReq } = bindActionCreators(projectActions, dispatch);

  // Pause the audio on initial render
  useEffect(() => {
    audio.pause();
  }, []);

  // Fetch and load the audio file
  const fetchAudio = async () => {
    try {
      const response = await fetch(path || "");
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setAudio(new Audio(url));
        audioReq(true);
      } else {
        console.error("Failed to fetch audio file:", response.status);
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  addEventListener("popstate", () => {
    audio.currentTime = 0;
    audio.pause();
  });
  useEffect(() => {
    if (isPlay) {
      audio.pause();
      audio.currentTime = timeStamp;
      audio.play();
      if (isFirstTime) {
        audioAnalyzer();
        setFirstTime(false);
      }
    } else {
      audio.pause();
      audio.currentTime = timeStamp;

      if (path) {
        fetchAudio();
      }
    }
  }, [path]);

  // Handle browser navigation
  useEffect(() => {
    const handlePopState = () => {
      audio.currentTime = 0;
      audio.pause();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [audio]);

  // Play or pause audio and manage timestamp updates
  useEffect(() => {
    const handlePlayback = async () => {
      if (isPlay) {
        try {
          audio.pause();
          audio.currentTime = timeStamp || 0;
          await audio.play();
          if (isFirstTime) {
            audioAnalyzer();
            setFirstTime(false);
          }
        } catch (error) {
          console.error("Error playing audio:", error);
        }
      } else {
        audio.pause();
        audio.currentTime = timeStamp || 0;
      }
    };

    handlePlayback();

    return () => {
      audio.pause();
      audio.currentTime = 0;
      // setAudio(new Audio());
    };
  }, [isPlay, timeStamp, click]);

  // Set up the audio analyzer
  const audioAnalyzer = () => {
    const audioCtx = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const analyzer = audioCtx.createAnalyser();
    analyzer.fftSize = 1024;

    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const source = audioCtx.createMediaElementSource(audio);
    source.connect(analyzer);
    source.connect(audioCtx.destination);
    source.onended = () => {
      source.disconnect();
    };

    setAnalyzerData({ analyzer, bufferLength, dataArray });
  };

  return (
    <div className="analyzer">
      <WaveUi analyzerData={analyzerData} />
      <div
        style={{
          height: 40,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
