import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  Box,
  Table,
  Center,
  Loader,
  Button,
  Grid,
  Text,
  TextInput,
  Input,
  Flex,
  Textarea,
  Pagination,
  Modal,
  useMantineTheme,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { Dropzone } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { Add, Delete, MovieCreation } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import {
  closeFeedbackReq,
  createFeedbackReq,
  getFeedbackDeleteReq,
  getFeedbackListReq,
} from "../dashboard/service/service";
import CustomModal from "../../component/customModal";
import { feedbackValidationSchema } from "../../constant/validation";
import TagsInput from "../../component/TagsInput";
import { API_CONFIG } from "../../constant/constant";
import { useDebounce } from "../../hooks";
const Feedback = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [feedbackList, setFeedbackList] = useState<any>([]);
  const [update, setUpdate] = useState(false);
  const [opened, setOpened] = useState(false);
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const [delConfirmationOpened, setDelConfirmationOpened] = useState(false);
  const [delId, setDelId] = useState("");
  const [isSubmitted, setSubmitted] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;
  const tagsInputRef = useRef<any>(null);
  const [error, setError] = useState("");
  const [feedbackToClose, setFeedbackToClose] = useState<any>(null);
  let interval;
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    tags: "",
    attachments: [],
    endDate: null,
  });
  const [questions, setQuestions] = useState<string[]>([]);
  const [newQuestion, setNewQuestion] = useState("");
  const currentPageRef = useRef<number>();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getFeedbackListReq(itemsPerPage, currentPage)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        if (res.data.length <= 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        setLoading(false);
        setFeedbackList(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setFeedbackList([]);
        }
      });
  }, [currentPage, update, navigate]);
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);
  const handleRefresh = () => {
    getFeedbackListReq(itemsPerPage, currentPageRef.current)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        if (res.data.length <= 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        setLoading(false);
        setFeedbackList(res);
        setUpdate(false);
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setFeedbackList([]);
        }
      });
  };
  const handleSubmit = async (values: any) => {
    if (questions.length > 0 && questions.length < 11) {
      try {
        setSubmitted(false);
        const tags = tagsInputRef.current?.getTags();
        const endDate = new Date(values.endDate).toISOString().split("T")[0];
        const formValue = {
          title: values.title,
          description: values.description,
          tags: tags,
          attachments: values.attachments,
          endDate: endDate,
          questions: questions,
        };
        const data = new FormData();
        Object.entries(formValue).forEach(([keyName, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              data.append(keyName, item);
            });
          } else {
            data.append(keyName, value);
          }
        });

        await createFeedbackReq(data);
        setUpdate(true);
        setOpened(false);
        setSubmitted(true);
        setQuestions([]);
        notifications.show({
          message: "Feedback created successfully",
          color: "green",
          autoClose: 2000,
          icon: <CheckIcon />,
        });
      } catch (err: any) {
        setSubmitted(true);
        notifications.show({
          message: err?.message || "An error occurred!",
          color: "red",
          autoClose: 2000,
          icon: <ErrorIcon />,
        });
      }
    } else {
      if (questions.length === 0) {
        toast.error("Please add at least one question");
      } else if (questions.length > 10) {
        toast.error("You can add up to 10 questions");
      } else {
        toast.error("Please fill out all required fields");
      }
    }
  };
  const openDelModal = (id: any) => {
    setDelConfirmationOpened(true);
    setDelId(id);
  };
  const handleDelete = async () => {
    try {
      await getFeedbackDeleteReq(delId);
      setUpdate(true);
      setDelConfirmationOpened(false);
      toast.success("Feedback deleted successfully");
    } catch (error) {
      console.log("An error occurred while deleting feedback", error);
      toast.error("An error occurred while deleting feedback");
    }
  };

  const handleCloseFeedback = (feedback: any) => {
    setFeedbackToClose(feedback);
    setConfirmationOpened(true);
  };

  const closeFeedback = async () => {
    try {
      if (feedbackToClose) {
        await closeFeedbackReq(feedbackToClose);
        setUpdate(true);
        setConfirmationOpened(false);
        notifications.show({
          message: "Feedback closed successfully",
          color: "green",
          autoClose: 2000,
          icon: <CheckIcon />,
        });
      }
    } catch (error) {
      notifications.show({
        message: "Failed to close feedback",
        color: "red",
        autoClose: 2000,
        icon: <ErrorIcon />,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCopyLink = async (responseLink: string) => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/surveyCine/${responseLink}`
      );
      toast.success("Response Link copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy: ", error);
      toast.error("Failed to copy link");
    }
  };

  const handleAddQuestion = () => {
    if (questions.length >= 10) {
      setError("You can only add up to 10 questions.");
      return;
    }

    if (newQuestion.trim() !== "") {
      setQuestions([...questions, newQuestion.trim()]);
      setNewQuestion("");
      setError("");
    } else {
      setError("Please enter a valid question.");
    }
  };

  const handleRemoveQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    setError("");
  };
  const handleClose = () => {
    setOpened(false);
    setQuestions([]);
  };
  useEffect(() => {
    if (search === "") {
      interval = setInterval(() => handleRefresh(), 6000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [search]);
  const fetchFeedback = async (filterText: any) => {
    try {
      const res = await getFeedbackListReq(
        itemsPerPage,
        currentPage,
        filterText
      );
      setFeedbackList(res);
    } catch (error) {
      window.alert(error);
    }

    setSearch(filterText);
  };
  const debouncedFetchProjects = useDebounce(fetchFeedback, 500);
  return (
    <>
      <Modal
        opened={confirmationOpened}
        onClose={() => setConfirmationOpened(false)}
        title="Do you want to stop taking responses?"
      >
        <Button
          onClick={closeFeedback}
          variant="outline"
          color="red"
          style={{ marginRight: "20px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setConfirmationOpened(false)}
          variant="outline"
          color="gray"
        >
          No
        </Button>
      </Modal>
      <Modal
        opened={delConfirmationOpened}
        onClose={() => setDelConfirmationOpened(false)}
        title="Are you sure you want to delete this feedback?"
      >
        <Button
          onClick={handleDelete}
          variant="outline"
          color="red"
          style={{ marginRight: "20px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setDelConfirmationOpened(false)}
          variant="outline"
          color="gray"
        >
          No
        </Button>
      </Modal>

      <CustomModal
        isOpen={opened}
        onClose={handleClose}
        title="New"
        size="60rem"
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={feedbackValidationSchema}
        >
          {({
            values,
            touched,
            handleBlur,
            handleChange,
            errors,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Flex direction="row" justify="space-between">
                {" "}
                <Box style={{ flex: "1" }} mx={14}>
                  <fieldset style={{ border: "none" }}>
                    <Input.Wrapper
                      label="Question"
                      m={10}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      {" "}
                      <TextInput
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        placeholder="Enter your question"
                      />
                      {error !== "" && (
                        <Center>
                          <Text color="red" mt={10}>
                            {error}
                          </Text>
                        </Center>
                      )}
                    </Input.Wrapper>

                    <Button onClick={handleAddQuestion} mt={10} mx={10}>
                      Add
                    </Button>
                  </fieldset>
                  <Box mx={10} my={10}>
                    <Text weight={700} mt={10}>
                      Questions:
                    </Text>
                  </Box>
                  <Box
                    style={{ maxHeight: "380px", overflowY: "auto" }}
                    mx={10}
                  >
                    {questions.length > 0 && (
                      <Input.Wrapper>
                        {questions.map((question, index) => (
                          <Flex
                            key={index}
                            align="center"
                            my={11}
                            p={10}
                            style={{
                              backgroundColor: "#e8e9eb",
                              borderRadius: "8px",
                            }}
                          >
                            <Text mr={10}>{index + 1}.</Text>{" "}
                            <Text style={{ flex: "1" }}>{question}</Text>{" "}
                            <Button
                              variant="subtle"
                              color="red"
                              onClick={() => handleRemoveQuestion(index)}
                              ml="auto"
                              style={{ padding: "5px 10px" }}
                            >
                              <Delete />
                            </Button>
                          </Flex>
                        ))}
                      </Input.Wrapper>
                    )}
                  </Box>
                </Box>
                <Box style={{ flex: "1" }} mx={14}>
                  {" "}
                  <fieldset style={{ border: "none" }}>
                    <Input.Wrapper
                      label="Title"
                      m={10}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <TextInput
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.title && errors.title
                            ? `${errors.title}`
                            : null
                        }
                      />
                    </Input.Wrapper>

                    <Input.Wrapper
                      label="Description"
                      m={10}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <Textarea
                        name="description"
                        placeholder="Add Description..."
                        minRows={2}
                        maxRows={4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.description && errors.description
                            ? `${errors.description}`
                            : null
                        }
                      />
                    </Input.Wrapper>

                    <Input.Wrapper
                      m={10}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <label htmlFor="" style={{ fontWeight: "bold" }}>
                        Tags
                      </label>
                      <TagsInput
                        error={false}
                        ref={tagsInputRef}
                        onChange={handleChange}
                      />
                    </Input.Wrapper>

                    <Input.Wrapper
                      m={10}
                      mt={20}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <label htmlFor="" style={{ fontWeight: "lighter" }}>
                        Attachments (Select Multiple Files)
                      </label>
                      <Dropzone
                        onDrop={(files) => {
                          setFieldValue("attachments", [
                            ...values.attachments,
                            ...files,
                          ]);
                        }}
                        onBlur={handleBlur}
                        name="attachments"
                        style={{
                          borderColor:
                            errors.attachments && touched.attachments
                              ? "red"
                              : "",
                        }}
                      >
                        {values.attachments.length === 0 && (
                          <Text align="center">Drop File here</Text>
                        )}
                        {values.attachments.length > 0 && (
                          <div>
                            {values.attachments.map(
                              (file: File, index: number) => (
                                <Flex key={index}>
                                  <div>
                                    <MovieCreation />
                                  </div>
                                  <Text align="center">{file.name}</Text>
                                </Flex>
                              )
                            )}
                          </div>
                        )}
                      </Dropzone>
                      {errors.attachments && touched.attachments && (
                        <Text color="red" align="left" mt={2}>
                          {errors.attachments as string}
                        </Text>
                      )}
                    </Input.Wrapper>

                    <Input.Wrapper
                      m={10}
                      mt={20}
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <DateInput
                        id="endDate"
                        name="endDate"
                        value={values.endDate}
                        onChange={(date) => setFieldValue("endDate", date)}
                        label="End Date"
                        placeholder="Select End Date"
                        minDate={new Date()}
                        error={touched.endDate && (errors.endDate as string)}
                      />
                    </Input.Wrapper>

                    {/* <Input.Wrapper m={10} mt={20}>
                      <Button
                        onClick={() => setQuestionModalOpened(true)}
                        variant="outline"
                        leftIcon={<Add />}
                      >
                        Add Question
                      </Button>
                    </Input.Wrapper> */}

                    <Input.Wrapper m={10} mt={30}>
                      <Button
                        type="submit"
                        variant="filled"
                        leftIcon={
                          !isSubmitted ? (
                            <Loader size={20} color="#ffffff" />
                          ) : null
                        }
                      >
                        Submit
                      </Button>
                    </Input.Wrapper>
                  </fieldset>
                </Box>
              </Flex>
            </form>
          )}
        </Formik>
      </CustomModal>

      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Grid my={"2px"} gutter={"xs"}>
          <Grid.Col sm={6} md={4} lg={4}>
            <Text style={{ fontSize: "25px", fontWeight: 700 }}>
              SurveyCine
            </Text>
          </Grid.Col>
          <Grid.Col sm={6} md={4} lg={8}>
            <Flex justify={"right"} align={"center"} gap={20}>
              <TextInput
                autoFocus
                placeholder="Search"
                onChange={(e) => debouncedFetchProjects(e.target.value)}
              />
              <Button
                variant="gradient"
                style={{ float: "right" }}
                leftIcon={<Add />}
                onClick={() => setOpened(true)}
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        <Box style={{ marginTop: "25px" }}>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Title</th>
                <th>Description</th>
                <th style={{ textAlign: "center" }}>Tags</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Closed</th>
                <th style={{ textAlign: "center" }}>End Date</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={12}>
                    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                      <Loader variant="bars" />
                    </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {feedbackList.data?.map((data: any, index: number) => (
                  <tr key={index}>
                    <td width={300} align="center">
                      <Text>{data.title || "-"}</Text>
                    </td>
                    <td width={400}>
                      <Text my={"xs"} size={"sm"}>
                        {data.description || "-"}
                      </Text>
                    </td>
                    <td width={250} align="center">
                      <Text>{data.tags.join(", ") || "-"}</Text>
                    </td>
                    <td width={250} align="center">
                      <Text
                        style={{
                          color: `${
                            data.status === "Completed" &&
                            data?.isSaveEmbeddings
                              ? "green"
                              : data.status === "Failed" &&
                                !data?.isSaveEmbeddings
                              ? "red"
                              : "rgb(0, 30, 107)"
                          }`,
                        }}
                      >
                        {data.status || "-"}
                      </Text>
                    </td>
                    <td width={150} align="center">
                      <div style={{ color: data.isClosed ? "green" : "red" }}>
                        <Text>{data.isClosed ? "True" : "False"}</Text>
                      </div>
                    </td>
                    <td width={150} align="center">
                      <Text>
                        {(data.endDate &&
                          new Date(data.endDate)
                            .toISOString()
                            .split("T")[0]
                            .replace(/-/g, " - ")) ||
                          "-  "}
                      </Text>
                    </td>
                    <td align="center">
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Button
                          title="View Questions"
                          variant="filled"
                          color="light"
                          size="xs"
                          onClick={() => {
                            navigate(`/surveyCine/${data._id}`);
                          }}
                        >
                          <Text>View</Text>
                        </Button>
                        <Button
                          title="Copy Link"
                          variant="filled"
                          color="green.6"
                          p={6}
                          size="xs"
                          onClick={() => handleCopyLink(data._id)}
                        >
                          <Text>Copy Link</Text>
                        </Button>
                        <Button
                          title="Generate PDF Report"
                          variant="filled"
                          color="cyan.6"
                          p={6}
                          size="xs"
                          disabled={data.status !== "pending"}
                          onClick={() => {
                            navigate(`/surveyCine/report/${data._id}`);
                          }}
                        >
                          View Report
                        </Button>
                        <Button
                          title="Close SurveyCine"
                          variant="filled"
                          color="orange"
                          p={6}
                          size="xs"
                          onClick={() => handleCloseFeedback(data._id)}
                          disabled={data.isClosed}
                        >
                          <Text>Close</Text>
                        </Button>
                        <Button
                          title="Remove from SurveyCine"
                          variant="filled"
                          color="red"
                          p={6}
                          size="xs"
                          onClick={() => {
                            openDelModal(data._id);
                          }}
                        >
                          <Text>Delete</Text>
                        </Button>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          <br />
          <br />
          <Pagination
            value={currentPage}
            onChange={handlePageChange}
            total={feedbackList?.totalPages || 1}
            style={{ position: "absolute", right: 30, bottom: 25 }}
          />
        </Box>
      </Card>
    </>
  );
};

export default Feedback;
