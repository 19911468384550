import React, { forwardRef, Ref } from "react";
import banner from "../../assets/images/banner.jpeg";
interface SummaryProps {
  classes: any;
  details: string;
  title: string;
  isNeedImg: boolean;
  detailTitle?: string;
}

const Summary = forwardRef<HTMLDivElement, SummaryProps>((props, ref) => {
  const title = String(props?.title);
  const summery = String(props?.details);
  const classes = props.classes;
  return (
    <>
      <h1>{title}</h1>
      <div className={classes.section1}>
        {props?.isNeedImg ?? (
          <div className={classes.itemImg}>
            <img src={banner} alt="images" className={classes.img} />
          </div>
        )}
        <div className={classes.item}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3 style={{ fontSize: "1.4rem" }}>{props?.detailTitle || ""}</h3>
          </div>
          <p style={{ color: "#b1b1b1" }}>{summery}</p>
        </div>
      </div>
    </>
  );
});

Summary.displayName = "Summary";

export default Summary;
