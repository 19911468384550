/**
 * eslint-disable no-useless-escape
 *
 * @format
 */

import * as Yup from "yup";

export const loginSchema = Yup.object({
  username: Yup.string().required("Please Enter Your Username"),
});

export const signupSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNo: Yup.string().required("Mobile number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /[a-zA-Z]/,
      "Password must contain at least one letter (uppercase or lowercase)"
    )
    .matches(/[0-9]/, "Password must contain at least one number")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  agreeWithTermsAndConditions: Yup.bool().oneOf(
    [true],
    "Please accept the terms and conditions"
  ),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /[a-zA-Z]/,
      "Password must contain at least one letter (uppercase or lowercase)"
    )
    .matches(/[0-9]/, "Password must contain at least one number")
    .required("New password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your password"),
});
