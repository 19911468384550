/** @format */
export const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    list: "project/list",
    project: "project",
    feedbacklist: "feedback/request-list",
    feedbackReport: "feedback/feedback-report",
    feedbackResponseInfo: "feedback-response/add-response-info",
    feedbackResponse: "feedback-response/add-response",
    feedbackResponseList: "feedback-response/response-list",
    addFeedbackResponse: "feedback-response/add",
    responsedel: "feedback-response/delete",
    feedback: "feedback/add-feedback-request",
    feedbackQuestionDetails: "feedback/question-detail",
    feedbackdel: "feedback/delete",
    feedbackclose: "feedback/request-close",
    feedbackDetails: "feedback/feedback-detail",
    feedbackResDetails: "feedback-response/detail",
    questionList: "feedback/question-list",
    questioneport: "feedback/question-detail",
    login: "auth/login",
    signup: "auth/sign-up",
    forgot: "auth/forgot-password",
    reset: "auth/reset-password",
    refreshToken: "auth/refresh-token",
    chat: "project/chat",
    report: "project/report",
    responsesReport: "feedback-response/report",
  },
};
export const getUrl = (url: string, params?: any): string => {
  let urlString = `${API_CONFIG.baseUrl}/${url}`;
  const url1 = new URLSearchParams(params);
  if (params && Object.keys(params).length > 0) {
    urlString += `?${url1}`;
  }
  return urlString;
};
export function convertToMinutesAndSeconds(timeInSeconds) {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = (timeInSeconds % 60).toFixed(0);

  if (hours > 0) {
    return `${hours} h ${minutes} m ${seconds} s`;
  } else {
    return `${minutes} m ${seconds} s`;
  }
}
