/** @format */

import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import { resetPasswordSchema } from "./validation";
import { postResetPasswordReq } from "./service/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const initialResetPasswordValue = {
  newPassword: "",
  confirmPassword: "",
};

export default function ResetPasswordPage() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialResetPasswordValue,
      validationSchema: resetPasswordSchema,
      onSubmit: (values) => {
        postResetPasswordReq(values?.newPassword, token)
          .then((response) => {
            navigate("/login");

            toast.success("Your password is updated!");
            setError("");
          })
          .catch((err) => {
            setError(
              "An error occurred while updating your password. Please try again."
            );
          });
      },
    });

  return (
    <Box>
      <Center style={{ width: "100%", height: "100vh" }}>
        <Container p={10} style={{ width: "100%", maxWidth: "400px" }}>
          <Card
            radius={10}
            shadow={"lg"}
            style={{
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            p={30}
          >
            <Box my={10}>
              <Center>
                <Text color={theme.colors.blue[4]} weight={700}>
                  Reset Your Password
                </Text>
              </Center>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextInput
                my={7}
                mx={10}
                value={values.newPassword}
                type={isShowPassword ? "text" : "password"}
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    New Password
                  </p>
                }
                error={
                  touched.newPassword && errors.newPassword
                    ? errors.newPassword
                    : null
                }
                rightSection={
                  !isShowPassword ? (
                    <VisibilityOffIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[6]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[2]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowPassword((prev) => !prev)}
                    />
                  )
                }
              />

              <TextInput
                my={7}
                mx={10}
                value={values.confirmPassword}
                type={isShowConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Confirm Password
                  </p>
                }
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : null
                }
                rightSection={
                  !isShowConfirmPassword ? (
                    <VisibilityOffIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[6]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowConfirmPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color:
                          theme.colorScheme === "dark"
                            ? theme.colors.gray[2]
                            : theme.colors.gray[8],
                      }}
                      onClick={() => setIsShowConfirmPassword((prev) => !prev)}
                    />
                  )
                }
              />
              <Center mt={20} mx={5} mb={10}>
                <Button
                  fullWidth
                  type="submit"
                  variant="filled"
                  style={{ fontSize: "16px" }}
                >
                  Reset Password
                </Button>
              </Center>
            </form>
          </Card>
        </Container>
      </Center>
    </Box>
  );
}
