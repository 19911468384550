/** @format */

import * as Yup from "yup";
export const projectValidationSchema = val => {
  return Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
    // type: Yup.string().required("Project type is Required"),
    projectSrc: Yup.string().required("Please Add File or Link"),
    description: Yup.string()
      .max(200, "Character is Greater then 200 ")
      .required("Add Description"),
  });
};

export const feedbackValidationSchema = val => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),

    description: Yup.string()
      .max(200, "Character is Greater then 200 ")
      .required("Add Description"),
    // tags: Yup.string().required("Tags are required"),
    endDate: Yup.date().required("End date is required"),
    attachments: Yup.array().optional(),
  });
};

export const feedbackResponseValidationSchema = val => {
  return Yup.object({
    name: Yup.string().required("Your name is required"),
    suggestions: Yup.string().required("Feedback description is required"),
    contactInfo: Yup.string()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$|^\+?[1-9]\d{9,15}$/,
        "Contact info must be a valid email or phone number"
      )
      .required("Contact info is required"),
    feedbackFile: Yup.mixed()
      .test(
        "fileType",
        "Only image and video files are allowed",
        (value: any) =>
          value &&
          (value?.type?.startsWith("image/") ||
            value?.type?.startsWith("video/"))
      )
      .required("Feedback file is required"),
  });
};
