import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Badge, ActionIcon, TextInput, Group, Box, Text } from "@mantine/core";
import CloseIcon from "@mui/icons-material/Close";
interface TagsInputProps {
  onChange: (value: string) => void;
  error: any;
}
export interface TagsInputRef {
  getTags: () => string[];
}
const TagsInput = forwardRef<TagsInputRef, TagsInputProps>(
  ({ onChange, error }, ref) => {
    const [inputValue, setInputValue] = useState("");
    const [badges, setBadges] = useState<string[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      onChange(event.target.value);
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && inputValue.trim() !== "") {
        setBadges((prevBadges) => [...prevBadges, inputValue.trim()]);
        setInputValue("");
      }
    };

    const handleBadgeRemove = (index: number) => {
      setBadges((prevBadges) => prevBadges.filter((_, idx) => idx !== index));
    };
    useImperativeHandle(ref, () => ({
      getTags: () => badges,
    }));

    return (
      <Box
        style={{
          border: "1px solid",
          borderColor: error ? "red" : "#cfd4d9",
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Group
          display={"flex"}
          spacing={10}
          style={{
            justifyContent: "start",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            objectFit: "cover",
            maxWidth: "100%",
            padding: badges?.length > 0 ? "5px" : "0px",
          }}
        >
          {badges.map((badge, index) => (
            <Badge
              key={index}
              size="lg"
              color="blue"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 7px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Box
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    textOverflow: "ellipsis",
                  }}
                >
                  {badge}
                </Box>

                <ActionIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    padding: 0,
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CloseIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBadgeRemove(index)}
                  />
                </ActionIcon>
              </Box>
            </Badge>
          ))}
        </Group>

        <TextInput
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Add Tags"
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            minHeight: "40px",
            input: {
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
            },
          }}
        />
      </Box>
    );
  }
);
TagsInput.displayName = "TagsInput";
export default TagsInput;
