import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles/style.css";

const Notification = ({ id, type, message, duration, onClose, Icon }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className={`notification ${type}`}>
      <span className="notification-icon">{Icon && <Icon />}</span>
      <span>{message}</span>
      <button className="close-btn" onClick={() => onClose()}>
        X
      </button>
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["success", "error", "default"]),
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  Icon: PropTypes.elementType,
};

Notification.defaultProps = {
  type: "default",
  duration: 5000,
};

export default Notification;
