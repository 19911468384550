/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-useless-escape */
export function findClosestTimestamp(speechToText: any, trueValueScore: any) {
  // Helper function to preprocess text
  const preprocessText = text => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "") // Remove punctuation
      .replace(/\s{2,}/g, " ") // Remove extra spaces
      .trim(); // Trim leading/trailing whitespace
  };

  // Function to calculate Jaccard Similarity between two strings
  const getJaccardSimilarity = (a, b) => {
    const aSet = new Set(a.split(" "));
    const bSet = new Set(b.split(" "));
    const intersection = new Set([...aSet].filter(x => bSet.has(x)));
    const union = new Set([...aSet, ...bSet]);
    return intersection.size / union.size;
  };

  // Function to find the closest match based on Jaccard Similarity
  const findClosestMatch = text => {
    let closestTimestamp = null;
    let highestSimilarity = -Infinity;
    const preprocessedText = preprocessText(text);

    for (const [timestamp, data] of Object.entries(speechToText.data)) {
      const keyVal: any = data;
      const preprocessedSpeechText = preprocessText(keyVal.text);
      const similarity = getJaccardSimilarity(
        preprocessedText,
        preprocessedSpeechText
      );
      if (similarity > highestSimilarity) {
        highestSimilarity = similarity;
        closestTimestamp = timestamp;
      }
    }
    return closestTimestamp;
  };

  const results = {};
  const uniqueTimestamps = new Set();

  for (const [key, value] of Object.entries(trueValueScore)) {
    const text = value[1];
    const closestTimestamp = findClosestMatch(text);

    // Convert the closest timestamp to a unique whole number
    let uniqueWholeNumber = Math.round(parseFloat(closestTimestamp));

    // Ensure uniqueness
    while (uniqueTimestamps.has(uniqueWholeNumber)) {
      uniqueWholeNumber++;
    }

    uniqueTimestamps.add(uniqueWholeNumber);
    results[key] = uniqueWholeNumber;
  }

  return results;
}

export function findNearestTimestampsFromThumbnails(thumbnails, timeStampArr) {
  const thumbnailKeys = Object.keys(thumbnails).map(Number);
  const nearestTimestamps = [];

  timeStampArr.forEach(ts => {
    const nearest = thumbnailKeys.reduce((prev, curr) => {
      return Math.abs(curr - ts) < Math.abs(prev - ts) ? curr : prev;
    });

    thumbnails[`${nearest}`].link[0];
    nearestTimestamps.push(nearest);
  });

  return nearestTimestamps
    .sort((a, b) => a - b)
    .map(timeStamp => thumbnails[`${timeStamp}`].link[0]);
}

export function findEmotionIndex(videoTime, data) {
  for (let i = 0; i < data.emotion_analysis_llama.length; i++) {
    if (data?.emotion_analysis_llama[i]?.batch_timestamps) {
      const batch = data.emotion_analysis_llama[i];
      const minTime = parseFloat(batch.batch_timestamps[0]);
      const maxTime = parseFloat(
        batch.batch_timestamps[batch.batch_timestamps.length - 1]
      );

      if (videoTime >= minTime && videoTime <= maxTime) {
        const emotionArr: any = [
          batch?.primary_emotion,
          ...batch?.other_emotion,
          batch?.secondary_emotion,
          batch?.tertiary_emotion,
        ].filter(val => val);
        return emotionArr;
      }
    }
    return [];
  }
  return -1; // return -1 if the videoTime is not found in any batch_timestamps
}
export function convertToTimestampFormat(data) {
  const result = {};

  data?.forEach(item => {
    item.batch_timestamps.forEach(timestamp => {
      result[Number(Number(timestamp).toFixed(0))] = [
        item?.primary_emotion,
        ...item?.other_emotion,
        item?.secondary_emotion,
        item?.tertiary_emotion,
      ].filter(val => val && val !== "NULL");
    });
  });

  return result;
}

export function emotionBreakdownForGraph(data, total = "") {
  const allEmotions = Object.values(data).flat();
  const xAxisData = Array.from(new Set(allEmotions));
  const totalCount = total ? Number(total) : allEmotions.length;
  const emotionArr = [];
  xAxisData.forEach(emotion => {
    const emotionCount = allEmotions.filter(val => val === emotion).length;

    const percentage = ((emotionCount / totalCount) * 100).toFixed(0);
    emotionArr.push({
      emotion,
      count: Number(percentage),
    });
  });
  return emotionArr;
}
