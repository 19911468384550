/** @format */

import {
  IconLayoutDashboard,
  IconVideo,
  IconDevicesSearch,
} from "@tabler/icons-react";

export interface NAV_OPTION_INTERFACE {
  path: string;
  label: string;
  icon: JSX.Element;
}

interface NAV_OPTIONS {
  [nav: string]: NAV_OPTION_INTERFACE;
}

const NAV_OPTIONS: NAV_OPTIONS = {
  DASHBOARD: {
    path: "/dashboard",
    label: "Dashboard",
    icon: <IconLayoutDashboard />,
  },
  PROJECT: {
    path: "/instavidiq",
    label: "InstaVidIQ",
    icon: <IconVideo />,
  },
  FEEDBACK: {
    path: "/surveyCine",
    label: "SurveyCine",
    icon: <IconDevicesSearch />,
  },
};

export default NAV_OPTIONS;
