import React, { useEffect, useRef, useState } from "react";

interface Props {
  text: string[];
  videoDuration: number;
  timeStamp?: number;
  isPlayed?: boolean;
  prevText?: string[];
}

function PunctuationTyped({
  text,
  videoDuration,
  timeStamp = 0,
  isPlayed = false,
  prevText = [],
}: Props) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setDisplayText("");
    setCurrentIndex(0);
    setCharIndex(0);
  }, [timeStamp]);

  useEffect(() => {
    if (isPlayed) {
      if (currentIndex < text?.length) {
        const currentText = text[currentIndex];
        const totalChars = text.reduce((sum, t) => sum + t.length, 0);
        const charInterval = videoDuration / totalChars + 7; // Calculate interval per character

        if (charIndex < currentText?.length) {
          const timeout = setTimeout(() => {
            setDisplayText(prev => prev + currentText[charIndex]);
            setCharIndex(prev => prev + 1);
            if (containerRef.current) {
              containerRef.current.scrollTop =
                containerRef.current.scrollHeight;
            }
          }, charInterval);

          return () => clearTimeout(timeout);
        } else {
          setCharIndex(0);
          setCurrentIndex(prev => prev + 1);
        }
      }
    } else {
      setDisplayText(text?.join(""));
      setCharIndex(0);
      setCurrentIndex(text.length - 1);
    }
  }, [text, currentIndex, charIndex, videoDuration]);

  return (
    <div
      ref={containerRef}
      style={{
        overflowY: "auto",
        padding: "10px",
        maxHeight: "110px",
      }}
      dangerouslySetInnerHTML={{ __html: prevText.join("") + displayText }}
    />
  );
}

export default PunctuationTyped;
