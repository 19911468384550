/** @format */

import { useEffect } from "react";
import "./audio.css";
interface props {
  frequency: any;
  rangeStart?: any;
  rangeEnd?: any;
}

export function AudioMeter({ frequency, rangeStart, rangeEnd }: props) {
  useEffect(() => {
    const bars = document.getElementsByClassName("bar");

    for (let i = 0; i < bars.length; i++) {
      if (frequency / (50 / bars.length) > i) {
        bars[i].classList.add("on");
      } else {
        bars[i].classList.remove("on");
      }
    }
  });
  return (
    <div className="audio-progress">
      <div
        style={{
          display: "flex",
          justifySelf: "end",
          overflow: "visible",
          width: "65px",
          height: "4px",
          alignItems: "center",
          rotate: "180deg",
          marginLeft: "-31px",
        }}
      >
        <div className="bar"></div>
        <div>
          <p style={{ marginLeft: "10px", marginTop: "7px" }}> -50</p>
        </div>
      </div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div
        style={{
          display: "flex",
          justifySelf: "end",
          overflow: "visible",
          width: "65px",
          height: "5px",
          alignItems: "center",
          rotate: "180deg",
          marginLeft: "-18px",
        }}
      >
        <div className="bar"></div>
        <div>
          <p style={{ marginLeft: "10px", marginTop: "30px" }}>0</p>
        </div>
      </div>
    </div>
  );
}
