/** @format */

import { API_CONFIG } from "../../../constant/constant";
import httpService from "../../../http/http";

export const postLoginReq = (data) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.login,
        data,
        {},
        { contentType: "application/json" }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const postSignupReq = (data) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(
        API_CONFIG.path.signup,
        data,
        {},
        { contentType: "application/json" }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const postForgotPasswordReq = (email: string) => {
  return new Promise((resolve, reject) => {
    const data: any = { email: email };
    httpService
      .patch(API_CONFIG.path.forgot, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error("Error during password reset request:", error);
        reject(error);
      });
  });
};

export const postResetPasswordReq = (newPassword: any, accessToken: string) => {
  return new Promise((resolve, reject) => {
    const data: any = { newPassword: newPassword };

    httpService
      .patch(API_CONFIG.path.reset, data, {}, { Authorization: accessToken })
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const refreshAccessToken = (refreshToken: string) => {
  return new Promise((resolve, reject) => {
    const data: any = { refreshToken: refreshToken };

    httpService
      .patch(API_CONFIG.path.refreshToken, data, {})
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

