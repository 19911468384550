/** @format */


import loginReducer from "./feature/auth/redux/loginReducer";
import projectReducer from "./feature/projectDetail/redux/projectReducer";

import { combineReducers } from "redux";

/** @format */
const rootReducer = combineReducers({ projectReducer, loginReducer });
export { rootReducer };
