import { API_CONFIG, getUrl } from "../../../constant/constant";
import httpService from "../../../http/http";

export const getFeedbackQuestionDetail = (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.feedbackQuestionDetails}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFeedBackResponseDetail = (id, page = 1, pageSize = 10) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        `${API_CONFIG.path.feedbackResponseList}/${id}?pageSize=${pageSize}&page=${page}`
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const uploadFeedbackResponse = (form) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(`${API_CONFIG.path.addFeedbackResponse}`, form)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFeedbackReport = async (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.feedbackReport}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getFeedbackDetails = async (id) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`${API_CONFIG.path.feedbackDetails}/${id}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addFeedbackResponseInfo = async (payload) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(`${API_CONFIG.path.feedbackResponseInfo}`, payload)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addFeedbackResponse = async (payload) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(`${API_CONFIG.path.feedbackResponse}`, payload)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
