/** @format */

import { Modal } from "@mantine/core";
import React, { useEffect, useState } from "react";

const CustomModal = ({
  children,
  isOpen,
  onClose,
  title,
  size = "auto",
}: any) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      size={size}
      title={title}
      styles={{
        header: {
          paddingLeft: "30px",
        },
        title: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        close: {
          alignSelf: "flex-start",
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
